import React from 'react';

import {
  // Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {ZmPrimaryBtn, ZmSecondaryBtn, ZmTertiaryBtn, ZmWarningBtn} from 'Components/ZmButtons/index';
import {ZmDialog} from 'Components/ZmDialogs';


/**
 * ConfirmDialog Component
 * @param {Object} props
 * @param {ReactElement|string} props.titleContent
 * @param {ReactElement|string} props.bodyContent
 * @param {function} props.onConfirm callback on successful book response
 * @param {function} props.onCancel callback on dialog close
 * @param {boolean} props.open
 * @param {boolean} props.switchOrder will flip the order of the primary and secondary action buttons
 *  (defaults to primary on right)
 * @param {?string} props.confirmText
 * @param {?string} props.cancelText
 * @return {ReactElement}
 */
export default function ConfirmDialog(props) {
  const {
    bodyContent,
    cancelText,
    confirmText,
    disableCancelBtn,
    disableConfirmBtn,
    fullScreen,
    onCancel,
    onConfirm,
    open,
    switchOrder,
    sx,
    titleContent,
    type,
  } = props;

  const {t} = useTranslation();

  return (
    <ZmDialog fullScreen={fullScreen} open={open} sx={sx}>
      <DialogTitle sx={{
        fontWeight: 'bold',
      }}>{titleContent}</DialogTitle>
      <DialogContent>
        {bodyContent}
      </DialogContent>
      <DialogActions>
        {(switchOrder) ?
        <>
          {type==='warning' ? (
            <ZmWarningBtn
              size="small"
              onClick={onConfirm}
              disabled={disableConfirmBtn}
            >
              {confirmText || t('common.continue')}
            </ZmWarningBtn>
          ) : (
          <ZmPrimaryBtn
            size="small"
            onClick={onConfirm}
            disabled={disableConfirmBtn}
          >
            {confirmText || t('common.continue')}
          </ZmPrimaryBtn>
          )}
          <ZmTertiaryBtn
            size="small"
            onClick={onCancel}
            disabled={disableCancelBtn}
            aria-label="dialog-cancel-btn"
          >
            {cancelText || t('common.cancel')}
          </ZmTertiaryBtn>
        </> :
        <>
          <ZmSecondaryBtn
            size="small"
            onClick={onCancel}
            aria-label="dialog-cancel-btn"
            disabled={disableCancelBtn}
          >
            {cancelText || t('common.cancel')}
          </ZmSecondaryBtn>
          {type==='warning' ? (
            <ZmWarningBtn
              size="small"
              onClick={onConfirm}
              disabled={disableConfirmBtn}
            >
              {confirmText || t('common.continue')}
            </ZmWarningBtn>
          ) : (
          <ZmPrimaryBtn
            aria-label={t('common.confirm')}
            size="small"
            onClick={onConfirm}
            disabled={disableConfirmBtn}
          >
            {confirmText || t('common.continue')}
          </ZmPrimaryBtn>
          )}
        </>
        }
      </DialogActions>
    </ZmDialog>
  );
}
