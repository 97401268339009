import {lazy} from 'react';

import {Route, Routes, Navigate} from 'react-router-dom';

const HomeLayout = lazy(() => import('../pages/mobile/Home'));

const BookingPages = lazy(() => import('../pages/mobile/Home/BookingPages'));
const Events = lazy(() => import('../pages/mobile/Home/Events'));

const MobileRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Navigate to="/m/home/booking-pages" />} />
      <Route path="/home" element={<HomeLayout />}>
        <Route index element={<Navigate to="/m/home/booking-pages" />} />
        <Route path="booking-pages" element={<BookingPages />} />
        <Route path="events" element={<Events />} />
      </Route>
    </Routes>
  );
};

export default MobileRoutes;
