import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {getUserSmsLimit} from './HostSettingsStore';

import {getOrganizationSetting} from 'Api/ZCalendar';

export const fetchOrganizationSetting = createAsyncThunk(
  'organizationSetting/fetchOrganizationSetting',
  async (data, thunkAPI) => {
    const response = await getOrganizationSetting();
    if (!response.smsLimit) {
      // if sms is not forbidden at org level, fetch user level sms limit data
      thunkAPI.dispatch(getUserSmsLimit());
    }
    return response;
  }
);

export const organizationSettingState = createSlice({
  name: 'organizationSettingStore',
  initialState: {
    workflowAdminsOnly: false,
    createTeamAppointmentEnabled: true,
    vanityUrlEnabled: false,
    vanityUrl: '',
    availabilityOverride: true,
    shareAnythingQuestion: true,
    disabledConnectionServices: [],
    logoEnforceUse: false,
    emailNotificationEnabled: false,
    smsNotificationEnabled: false,
    isSmsLimited: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizationSetting.fulfilled, (state, action) => {
      state.workflowAdminsOnly = action.payload.workflowAdminsOnly;
      state.createTeamAppointmentEnabled = action.payload.createTeamAppointmentEnabled;
      state.disabledConnectionServices = action.payload.disabledConnectionServices ?? [];
      state.vanityUrlEnabled = !!action.payload.vanityUrlEnabled;
      if (action.payload.vanityUrlEnabled) {
        state.vanityUrl = action.payload.vanityUrl;
      }
      state.isSmsLimited = !!action.payload.smsLimit;
      state.availabilityOverride = action.payload.availabilityOverride ?? true;
      state.shareAnythingQuestion = action.payload.shareAnythingQuestion ?? true;
      state.logoEnforceUse = !!action.payload.logoEnforceUse;
      // If the field is not present in the org profile, notifications are default enabled.
      state.smsNotificationEnabled = action.payload.smsNotificationEnabled !== false;
      state.emailNotificationEnabled = action.payload.emailNotificationEnabled !== false;
    });
    builder.addCase(fetchOrganizationSetting.rejected, (state, action) => {
      state.workflowAdminsOnly = false;
      // set as true by default in case block crt users
      state.createTeamAppointmentEnabled = true;
    });
  },
});

export default organizationSettingState.reducer;
