import {nanoid} from '@reduxjs/toolkit';
import {compareAsc} from 'date-fns';
import DOMPurify from 'dompurify';
import {cloneDeep, merge, omit, pick} from 'lodash';

import {getRecurringApptDefaultSegments, isTeamAppt, isTeamApptType} from './apptAvailabilityUtils';
import {
  ADD_ON_TYPE,
  APPT_NOTIF_TYPE,
  APPT_TYPE,
  APPT_INTERVAL_TYPE,
  CAPACITY_TYPE,
  DAY_MINUTES,
  HOUR_MINUTES,
  PERIOD_TYPE,
  REDIRECT_TYPE,
  FORMAT,
  APPT_POOLING_TYPE,
  DISTRIBUTION_TYPE,
  SCOPE_TYPE,
} from './consts';
import DateWithTimeZone from './DateWithTimeZone';
import {getUserInfo} from './integration';
import Time from './Time';

import defaultCustomFields from 'Components/ApptForm/defaultCustomFields.json';
import {toDateTime, getFormatTime} from 'Components/BookingBlocksWidget/utils';
import {
  getCapacityType,
  getSafeScheduleColorValue,
  getVirtualUserEmail,
  getZCCVirtualUserEmail,
  strEq,
  virtualUserEmailToUid,
} from 'Utils';

const formatTime = (isoString) => {
  if (toDateTime(isoString, 'UTC').toMillis() === toDateTime(new Time(24), 'UTC').toMillis()) {
    // Recurrence seg time of next day, midnight must be passed as 24:00 to work
    return '24:00';
  }
  const time = toDateTime(isoString, 'UTC').toFormat('HH:mm');
  return time;
};

/**
 * Prepare the final segmentsRecurring api payload field by filtering out
 * temporary form state fields from segments object and transforming
 * segment.start and segment.end formats from date to time string.
 * @param {Object} formSegsRecurrenceState
 * @return {Object}
 */
export const prepareRecurrenceSegments = (formSegsRecurrenceState) => {
  if (formSegsRecurrenceState === undefined) {
    return {};
  }
  const submitSegmentsRecurring = {};
  const recurrenceSegs = formSegsRecurrenceState;
  Object.keys(recurrenceSegs)
    .forEach((dayOfWeek) => {
      if (recurrenceSegs[dayOfWeek]?.isChecked && recurrenceSegs[dayOfWeek].segments.length > 0) {
        const formattedSegs = recurrenceSegs[dayOfWeek].segments.map((seg) => ({
          start: formatTime(seg.start),
          end: formatTime(seg.end),
        }));
        formattedSegs.sort((seg1, seg2) => seg1.start < seg2.start ? -1 : 1);
        submitSegmentsRecurring[dayOfWeek] = formattedSegs;
      } else {
        // Server only accepts array of length > 1 || null
        submitSegmentsRecurring[dayOfWeek] = null;
      }
    });
  return submitSegmentsRecurring;
};

/**
 * @param {Object} formSegsState
 * @return {Object}
 */
export const prepareSegments = (formSegsState) => {
  return formSegsState
    ?.map((s) => {
      // remove UI attached id property
      return {
        start: s.start,
        end: s.end,
      };
    })
    .sort((a, b) =>
      compareAsc(
        new DateWithTimeZone(a.start),
        new DateWithTimeZone(b.start)
      )
    );
};

/**
 * Formats a date / date string to the shortened iso format
 * accepted by server api: YYYYmmddThhmmssZ
 * @param {Date|string} val
 * @return {string}
 */
const toRRIsoFormat = (val) => {
  const isoString = new Date(val).toISOString();
  // remove milliseconds
  const isoWithoutMs = isoString.split('.')[0] + 'Z';
  // remove special characters - : ' '
  const shortIso = Array.from(isoWithoutMs).filter((char) => !'-: '.includes(char)).join('');
  return shortIso;
};

/**
 * Gets the final recurrence rule string from form state values
 * @param {Object} recurrenceRangeOptions
 * @param {string} recurrenceRangeOptions.selectedRuleType 'unlimited' | 'fixed'
 * @param {string} timeZone
 * @return {string}
 */
export const getRecurrenceRule = (recurrenceRangeOptions, timeZone) => {
  let recurrence = 'RRULE:FREQ=WEEKLY';
  let recurrenceEndDate;
  if (recurrenceRangeOptions.selectedRuleType === APPT_INTERVAL_TYPE.UNLIMITED) {
    return recurrence;
  } else if (recurrenceRangeOptions.selectedRuleType === APPT_INTERVAL_TYPE.FIXED) {
    const dt = toDateTime(recurrenceRangeOptions.dateRange.endDate, timeZone, true).endOf('day').toUTC().toISO();
    recurrenceEndDate = toRRIsoFormat(dt);
  } else {
    console.warn('unknown recurrence end type', recurrenceRangeOptions.selectedRuleType);
  }
  recurrence += `;UNTIL=${recurrenceEndDate}`;
  return recurrence;
};

/**
 * @param {HostAttendee[]} modelAttendees
 * @return {OrgUser[]}
 */
export const modelToFormAdhocAttendees = (modelAttendees) => {
  return (modelAttendees || []).map((attendee) => {
    return {
      name: attendee.displayName,
      userId: attendee.email.split('@')[0],
      email: attendee.loginEmail || attendee.email,
    };
  });
};

/**
 * @param {OrgUser[]} formAttendees
 * @return {HostAttendee[]}
 */
export const formAdhocAttendeesToModel = (formAttendees) => {
  return formAttendees.map((formAttendee) => ({
    userId: formAttendee.userId,
    email: getVirtualUserEmail(formAttendee.userId).toLowerCase(),
    loginEmail: formAttendee.email,
    displayName: formAttendee.name,
  }));
};

export const modelToFormLocationConfiguration = (modelCfg) => {
  if (modelCfg.kind === ADD_ON_TYPE.IN_BOUND_CALL) {
    const {phoneNumber, ...rest} = modelCfg;
    const [hostPhoneCountryCode, hostPhoneNumber] = phoneNumber.split(' ');
    return {
      ...rest,
      hostPhoneCountryCode: hostPhoneCountryCode,
      hostPhoneNumber: hostPhoneNumber,
    };
  } else {
    return modelCfg;
  }
};

export const formAttendeeLocationConfigToModel = (formCfg) => {
  if (!formCfg || formCfg.kind === ADD_ON_TYPE.OFFLINE) {
    return {
      locationConfiguration: undefined,
      addOnType: ADD_ON_TYPE.OFFLINE,
      location: '',
    };
  }
  const {hostPhoneCountryCode, hostPhoneNumber, ...rest} = formCfg;
  let meetingSettings = {
    waitingRoom: true,
  };
  if (formCfg.kind === ADD_ON_TYPE.ZOOM_MEETING) {
    meetingSettings = formCfg.meetingSettings;
  }
  return ({
    meetingSettings: meetingSettings,
    locationConfiguration: {
      ...rest,
      ...formCfg.kind === ADD_ON_TYPE.IN_BOUND_CALL?
        {phoneNumber: hostPhoneCountryCode.split('-')[0] + ' ' + hostPhoneNumber}: null},
  });
};

/**
 * @param {{attendees: FormStateHostAttendee[], userPools: FormStateUserPool[]}} formValues
 * @param {string} apptType
 * @return {HostAttendee[]}
 */
export const formAttendeesToModel = (formValues, apptType) => {
  const isRRLocationMode = getPoolingLocationMode(apptType, formValues.userPools) === APPT_POOLING_TYPE.ROUND_ROBIN;
  const omitFields = (isRRLocationMode) ?
    ['editingLocation', 'displayLocation', 'rhfId'] :
    ['editingLocation', 'displayLocation', 'rhfId', 'addOnType', 'locationConfiguration', 'location'];
  // When is formValues.meta.isSameLocation applicable...? When
  // the first populated user pool is a round robin one (no req hosts).
  const modelAttendees = formValues.attendees
    // normalize emails and strip out temporary state fields
    .map((formAttendee) => omit(
      {
        ...formAttendee,
        email: formAttendee.email.toLowerCase(),
        ...isRRLocationMode && formAttendeeLocationConfigToModel(formAttendee.locationConfiguration),
      },
      omitFields,
    ));
  return modelAttendees;
};

export const modelToFormSlots = (modelSpots) => {
  return (modelSpots || []).map(({end, start, voters}) => ({
    name: '',
    startTime: new Date(start),
    endTime: new Date(end),
    voters: voters || [],
  }));
};

export const formToModelSlots = (formSlots) => {
  return [...formSlots]
    .sort((slot1, slot2) => compareAsc(new Date(slot1.startTime), new Date(slot2.startTime)))
    .map(({endTime, startTime}) => ({start: startTime, end: endTime}));
};

// Gets the form state values from the stored server appointment model
// server workflow stores cushion time in minutes before the event
// {minutes: -15} => 15 minutes after event end
export const modelToFormDurationInput = (minutes) => {
  minutes = Math.abs(minutes);

  const days = minutes % (DAY_MINUTES);
  const hours = minutes % 60;

  let durationUnit = 1;
  if (days === 0 && minutes !== 0) {
    durationUnit = DAY_MINUTES;
  } else if (hours === 0 && minutes !== 0) {
    durationUnit = HOUR_MINUTES;
  } else {
    durationUnit = 1;
  }
  return {
    durationUnit: durationUnit,
    durationAmount: minutes / durationUnit,
  };
};


// Creates the server appointment cushion payload fields from the form state values
export const formDurationInputToModel = (duration) => {
  if (!duration) {
    return null;
  }
  return duration.durationAmount * duration.durationUnit;
};

/**
 * Default 1:1 appts to calendar notifications and 1:m appts to email notifications,
 *  otherwise read the existing value
 * @param {Appt} appointment
 * @return {string}
 */
export const getApptNotificationType = (appointment) => {
  const capacityType = getCapacityType(appointment);
  if (capacityType === CAPACITY_TYPE.MULTIPLE) {
    return APPT_NOTIF_TYPE.EMAIL;
  } else {
    return appointment?.notificationType || APPT_NOTIF_TYPE.CALENDAR;
  }
};

/**
 * Gets the recurrence end date as iso string from recurrence rule,
 *  or undefined if the appt recurs forever
 * @param {string} iso
 * @return {?string}
 */
const getRecurrenceEnd = (iso) => {
  if (iso?.length > 0) {
    try {
      const [, , arr] = iso.split('=');
      const year = arr.slice(0, 4);
      const month = arr.slice(4, 6);
      const day = arr.slice(6, 11);
      const minute = arr.slice(11, 13);
      const second = arr.slice(13);
      const combine = year.concat('-', month, '-', day, ':', minute, ':', second);
      const date = new Date(combine);
      return date.toISOString();
    } catch (e) {
      // The appt recurs forever
      return undefined;
    }
  }
};

/**
 * @typedef FormRecurrenceRange
 * @property {'unlimited'|'fixed'} selectedRuleType
 * @property {Object} dateRange
 * @property {string} dateRange.startDate ISO Calendar Date
 * @property {string} dateRange.endDate ISO Calendar Date
 */

/**
 *
 * @param {Appt} appointment
 * @return {FormRecurrenceRange}
 */
export const modelToFormRecurrenceRange = (appointment) => {
  let startDate = appointment?.startDate;
  let endDate = appointment?.endDate;
  let intervalType = appointment.intervalType;
  if (!intervalType) {
    // Recurrence is old version of Create Api. The following code will be removed after server updated the old data.
    const recurrenceEnd = getRecurrenceEnd(appointment?.recurrence?.[0]);
    if (recurrenceEnd) {
      intervalType = APPT_INTERVAL_TYPE.FIXED;
      startDate = toDateTime(appointment?.start?.dateTime, appointment?.start?.timeZone).toISODate();
      endDate = toDateTime(recurrenceEnd, appointment?.start?.timeZone).toISODate();
    } else {
      intervalType = APPT_INTERVAL_TYPE.UNLIMITED;
      startDate = toDateTime(appointment?.start?.dateTime, appointment?.start?.timeZone).toISODate();
      // set to be start + 3 months as a form default
      endDate = toDateTime(startDate).plus({months: 3}).toISODate();
    }
  } else {
    // new version of Create Api
    if (appointment.intervalType === APPT_INTERVAL_TYPE.UNLIMITED) {
      endDate = toDateTime(startDate).plus({months: 3}).toISODate();
    }
  }
  return {
    selectedRuleType: intervalType,
    dateRange: {
      startDate: startDate,
      endDate: endDate,
    },
  };
};

/**
 *
 * @param {CustomField[]} customFields
 * @return {Object[]}
 */
export const modelToFormCustomFields = (customFields) => {
  return (customFields ?? []).map((cf) => {
    return {
      ...cf,
      // Convert string[] to Object[] since rhf useFieldArray only works with Object[]
      answerChoices: (cf.answerChoices ?? []).map((choiceStr) => ({value: choiceStr})),
      format: cf.format === FORMAT.SELECT ? FORMAT.CHOICES_ONE : cf.format,
      isDropdown: cf.format === FORMAT.SELECT ? true : cf.isDropdown,
    };
  });
};

/**
 *
 * @param {Object[]} fCustomFields
 * @return {CustomField[]}
 */
export const formCustomFieldsToModel = (fCustomFields) => {
  return (fCustomFields ?? []).map((formCustomField, idx) => {
    const item = {...formCustomField};
    item.position = idx;
    if ([FORMAT.TEXT, FORMAT.PHONE_NUMBER, FORMAT.STRING].includes(item.format)) {
      item.isDropdown = false;
      delete item.answerChoices;
    } else {
      item.answerChoices = (item.answerChoices ?? []).map((choice) => choice.value);
    }
    if (!item.includeOther || item.format === FORMAT.SELECT) item.includeOther = false;
    if (item.isDropdown) {
      item.format = FORMAT.SELECT;
    };
    return item;
  });
};


export const modelToFormCustomNotifications = (appointment, defaultTemplates, enabledNotifMethods) => {
  return {
    ...modelToFormSystemNotifications(appointment, defaultTemplates),
    ...modelToFormReminderNotifications(appointment, defaultTemplates, enabledNotifMethods),
  };
};

export const formCustomNotificationsToModel = (notificationType, apptNotifTemplates) => {
  const systemNotifications = formSystemNotificationsToModel(notificationType, apptNotifTemplates);
  const standaloneNotifications = formReminderNotificationsToModel(notificationType, apptNotifTemplates);
  return {...systemNotifications, ...standaloneNotifications};
};

/**
 * @param {'roundRobin'|'collective'|'multiPool'} apptType
 * @param {Appt} appt
 * @param {FormStateHostAttendee[]} attendees
 * @return {FormStateUserPool[]}
 */
export const modelToFormUserPools = (apptType, appt, attendees) => {
  switch (apptType) {
    case APPT_POOLING_TYPE.COLLECTIVE:
      return [
        {
          id: nanoid(),
          name: '',
          poolingType: APPT_POOLING_TYPE.COLLECTIVE,
          selectedUsers: attendees.filter((att) => att.host).map((att) => ({value: att.email})),
        },
      ];
    case APPT_POOLING_TYPE.ROUND_ROBIN:
      return [
        {
          id: nanoid(),
          name: '',
          poolingType: APPT_POOLING_TYPE.COLLECTIVE,
          selectedUsers: [],
        },
        {
          id: nanoid(),
          name: '',
          poolingType: APPT_POOLING_TYPE.ROUND_ROBIN,
          selectedUsers: attendees.filter((att) => att.host).map((att) => ({value: att.email})),
          distribution: appt?.distribution ?? DISTRIBUTION_TYPE.AVAILABILITY,
        },
      ];
    default:
      const userPools = cloneDeep(appt?.userPools ?? []);
      userPools.forEach((pool) => {
        pool.selectedUsers = pool.selectedUsers.map((calId) => ({value: calId}));
      });
      // prepend an empty collective pool
      if (userPools?.length === 0 || userPools[0]?.poolingType !== APPT_POOLING_TYPE.COLLECTIVE) {
        return [
          {
            id: nanoid(),
            name: '',
            poolingType: APPT_POOLING_TYPE.COLLECTIVE,
            selectedUsers: [],
          },
          ...userPools,
        ];
      }
      return userPools;
  }
};

/**
 * @param {FormStateUserPool[]} userPools
 * @return {UserPool[]}
 */
export const formUserPoolsToModel = (userPools) => {
  const modelUserPools = userPools
    /**
     * Filter out empty user pools
     */
    .filter((pool) => !!pool.selectedUsers?.length)
    .map((val) => omit({
      ...val,
      selectedUsers: val.selectedUsers
        .map(({value: calIdEmail}) => calIdEmail)
        .map((calIdEmail) => calIdEmail.toLowerCase()),
    }, ['rhfId']));

  return modelUserPools;
};

/**
 * @param {ApptFormValues} formValues
 * @return {*}
 */
export const formPoolingStateToModel = (formValues) => {
  if (formValues.poolingType) {
    const userPools = formUserPoolsToModel(formValues.userPools);
    if (formValues.asScope?.type === SCOPE_TYPE.TEAM) {
      if (userPools.length > 1) {
        console.error('team-scoped appts cannot be multipool');
      }
      return {
        poolingType: userPools[0].poolingType,
        ...(userPools[0].poolingType === APPT_POOLING_TYPE.ROUND_ROBIN &&
          {distribution: userPools[0].distribution}
        ),
        // Team-scoped appts do not support userPools
      };
    }
    return {
      poolingType: APPT_POOLING_TYPE.MULTI_POOL,
      userPools: userPools,
    };
  }
  return {};
};

/**
 * Determine whether or not a host should be soft deleted (marked as host = false) or not (hard delete otherwise)
 * @param {HostAttendee} attendee
 * @param {*} existingAttendees
 * @return {boolean}
 */
export const shouldSoftDelete = (attendee, existingAttendees) => {
  console.log(attendee, existingAttendees);
  // Should hard delete hosts with connection issues
  if (attendee?.connectionState && attendee?.connectionState?.isError) {
    return false;
  }

  const isSelf = strEq(getUserInfo()?.calendarId, attendee.email);
  // Should soft delete when delete self
  if (isSelf) { // check if user being deleted is the current user themselves
    return true;
  }

  // Soft delete existing hosts
  // Hard delete newly added hosts (hosts removed before saving)
  return existingAttendees?.find((existingAttendee) => strEq(
    existingAttendee.email,
    attendee.email,
  ));
};

/**
 * Manages updating the given attendee and pool user field arrays depending on whether
 *  the user should be soft or hard deleted
 * Soft delete will only change the attendee.host field to false
 * Hard delete will remove the user from the attendee list
 * @param {HostAttendee} removingHost
 * @param {boolean} softDelete
 * @param {*} attendeeFieldArr
 * @param {*} poolUsersFieldArr
 * @return {void}
 */
export const removeUser = (removingHost, softDelete, attendeeFieldArr, poolUsersFieldArr) => {
  // when remove host, owner should not be deleted, we should set `host` field to false,
  // so that the owner can still manage this appointment
  console.debug(softDelete?'softDelete':'hardDelete', removingHost.displayName);
  const attendeeIdx = attendeeFieldArr.fields.findIndex(
    (attendee) => strEq(attendee.email, removingHost.email));

  const poolUserIdx = poolUsersFieldArr.fields.findIndex(({value}) => strEq(value, removingHost.email));

  if (attendeeIdx > -1) {
    if (softDelete) {
      attendeeFieldArr.update(attendeeIdx, {...attendeeFieldArr.fields[attendeeIdx], host: false});
    } else {
      attendeeFieldArr.remove(attendeeIdx);
    }
    poolUsersFieldArr.remove(poolUserIdx);
  } else {
    console.error('Cannot remove nonexistent user', removingHost, attendeeFieldArr.fields);
  }
};

/**
 * Read the existing appointment notification and store in the corresponding notification type's data
 *  else read in the default template data for that given notification type
 * @param {Appt} appointment
 * @param {Object.<string, CustomApptNotifications>} defaultTemplates
 * @return {Object.<string, CustomApptNotifications>}
 */
export const modelToFormSystemNotifications = (appointment, defaultTemplates) => {
  const notificationType = getApptNotificationType(appointment);

  // initialized to all defaults
  const formNotificationState = cloneDeep(defaultTemplates);
  if (appointment?.customNotifications) {
    if (
      appointment.customNotifications?.attendeeInvitation ||
      appointment.customNotifications?.attendeeCancellation ||
      appointment.customNotifications?.attendeeConfirmation
    ) {
      // read the new values to the matching fields, ignoring inviteeConfirmation
      merge(
        formNotificationState[notificationType],
        pick(appointment?.customNotifications,
          [
            'attendeeInvitation',
            'attendeeCancellation',
            'attendeeConfirmation',
          ]
        )
      );
    } else {
      // appointment with existing deprecated prefix calendar event setting
      if (appointment?.customNotifications?.inviteeConfirmation?.subject) {
        const normalizedSubject = appointment?.customNotifications?.inviteeConfirmation?.subject
          // variable name updated;
          // replace old attendee_name for new attendee_full_name variable
          .replace(/{{attendee_name}}/i, '{{attendee_full_name}}');
        const customNotifications = {
          attendeeInvitation: {
            subject: normalizedSubject,
            body: appointment?.customNotifications?.inviteeConfirmation?.body,
          },
        };
        merge(
          formNotificationState[notificationType],
          customNotifications
        );
      }
    }
  }
  return formNotificationState;
};

/**
 * Pass the customized template strings depending on the notificationType
 * @param {string} notificationType
 * @param {Object.<string, CustomApptNotifications>} apptNotifTemplates
 * @return {CustomApptNotifications}
 */
export const formSystemNotificationsToModel = (notificationType, apptNotifTemplates) => {
  return apptNotifTemplates[notificationType];
};


/**
 * Read the existing appointment notification and store in the corresponding notification type's data
 *  else read in the default template data for that given notification type
 * @param {Appt} appointment
 * @param {Object.<string, CustomApptNotifications>} defaultTemplates
 * @param {{email: boolean, text: boolean}} enabledNotifMethods
 * @return {Object.<string, CustomApptNotifications>}
 */
export const modelToFormReminderNotifications = (appointment, defaultTemplates, enabledNotifMethods) => {
  // initialized to base defaults
  const formNotificationState = {
    attendeeEmailReminder: {
      subject: '',
      body: '',
      enabled: false,
      minutes: [1440],
    },
    attendeeSmsReminder: {
      subject: '',
      body: '',
      enabled: false,
      minutes: [1440],
    },
    attendeeEmailFollowUp: {
      subject: '',
      body: '',
      enabled: false,
      minutes: [-1440],
    },
  };
  // additionally set according to loaded server template defaults
  merge(
    formNotificationState,
    cloneDeep(pick(defaultTemplates,
      [
        'attendeeEmailReminder',
        'attendeeSmsReminder',
        'attendeeEmailFollowUp',
      ]
    )));
  if (!appointment?.id && isTeamAppt(appointment)) {
    // If creating a new team appt, initially enable the email reminder.
    formNotificationState.attendeeEmailReminder.enabled = true;
  }

  // additionally apply appt customizations
  if (appointment?.customNotifications) {
    if (
      appointment.customNotifications?.attendeeEmailReminder ||
      appointment.customNotifications?.attendeeSmsReminder ||
      appointment.customNotifications?.attendeeEmailFollowUp
    ) {
      // read the new values to the matching fields, ignoring inviteeConfirmation
      merge(
        formNotificationState,
        pick(appointment?.customNotifications,
          [
            'attendeeEmailReminder',
            'attendeeSmsReminder',
            'attendeeEmailFollowUp',
          ]
        )
      );
    }
  }

  if (!enabledNotifMethods.text) {
    formNotificationState.attendeeSmsReminder.enabled = false;
  }
  if (!enabledNotifMethods.email) {
    formNotificationState.attendeeEmailReminder.enabled = false;
    formNotificationState.attendeeEmailFollowUp.enabled = false;
  }

  return formNotificationState;
};

/**
 * Pass the customized template strings depending on the notificationType
 * @param {string} notificationType
 * @param {Object.<string, CustomApptNotifications>} apptNotifTemplates
 * @return {CustomApptNotifications}
 */
export const formReminderNotificationsToModel = (notificationType, apptNotifTemplates) => {
  const standaloneNotifications = {...omit(apptNotifTemplates, Object.values(APPT_NOTIF_TYPE))};
  return standaloneNotifications;
};


/**
 *
 * @param {Appt} appt
 * @return {boolean}
 */
export const shouldCollapseCustomFields = (appt) => {
  const apptCustomFields = appt?.customFields || [];
  // Default collapse section when custom fields empty or not edited from default values
  return (
    apptCustomFields.length === 0 ||
    (
      apptCustomFields.length === defaultCustomFields.length &&
      defaultCustomFields.every((defaultCustomField, i) =>
        Object.keys(defaultCustomField).every(
          (key) => defaultCustomField[key] === apptCustomFields[i]?.[key]
        )
      )
    )
  );
};

export const daysToMinutes = (days) => {
  if (!days) {
    return null;
  }
  return days * DAY_MINUTES;
};

/**
 * @param {*} apptType
 * @param {*} userPools
 * @return {'roundRobin'|'collective'}
 */
export const getPoolingLocationMode = (apptType, userPools) => {
  if (isTeamApptType(apptType)) {
    const pools = (userPools ?? []);
    const hasRequiredHosts = pools[0]?.selectedUsers?.length && pools[0]?.poolingType === APPT_POOLING_TYPE.COLLECTIVE;
    const hasRotatingHosts = pools
      .some(
        (pool) => pool.poolingType === APPT_POOLING_TYPE.ROUND_ROBIN && pool.selectedUsers.length
      );
    if (hasRotatingHosts && !hasRequiredHosts) {
      return APPT_POOLING_TYPE.ROUND_ROBIN;
    }
  }
  return APPT_POOLING_TYPE.COLLECTIVE;
};

export const allowLocationConfigurations = (apptType, isSameLocation, userPools) => {
  if ((!userPools || userPools.length === 0) || !isTeamApptType(apptType)) {
    return true;
  }
  const ups = userPools ?? [];
  const hasRequiredHosts = ups[0]?.selectedUsers?.length;
  if (hasRequiredHosts) {
    return true;
  }
  return isSameLocation;
};

/**
 * Sets the top-level appt locations, addOnType, locationConfigurations values from the form state
 * If the appt is roundRobin (no req hosts) then the top-level value will not be set
 * because the locations will be set in the attendee items themselves.
 * @param {*} apptType
 * @param {*} formValues
 * @param {*} meetingSettingDefaults
 * @return {*}
 */
export const formLocationConfigsToModel = (apptType, formValues, meetingSettingDefaults) => {
  let locations = (formValues.locationConfigurations ?? [])?.filter((cfg) => cfg.kind !== ADD_ON_TYPE.OFFLINE);
  if (isTeamApptType(apptType)) {
    // Only allow a single location for collective pooling type
    if (getPoolingLocationMode(apptType, formValues.userPools) === APPT_POOLING_TYPE.COLLECTIVE) {
      locations = locations.slice(0, 1);
    } else {
      // is RR only => do not pass any locationConfigurations on the top level.
      return {
        location: '',
        addOnType: ADD_ON_TYPE.OFFLINE,
        locationConfigurations: [],
      };
    }
  }

  let meetingSettings = {
    waitingRoom: false,
  };
  const modelLocationConfigs = locations.map((cfg) => {
    const {hostPhoneCountryCode, hostPhoneNumber, ...rest} = cfg;
    // Pending change to store the meetingSetting within the location configuration itself,
    // but in the meantime also store it in the old place.
    if (cfg.kind === ADD_ON_TYPE.ZOOM_MEETING) {
      if (meetingSettingDefaults?.waitingRoomNew?.locked || meetingSettingDefaults?.usePMISchedule?.value) {
        meetingSettings = {
          waitingRoom: meetingSettingDefaults?.waitingRoomNew?.value,
        };
        cfg.meetingSettings = {
          waitingRoom: meetingSettingDefaults?.waitingRoomNew?.value,
        };
      } else {
        meetingSettings = cfg.meetingSettings;
      }
    }
    return {
      ...rest,
      ...cfg.kind === ADD_ON_TYPE.IN_BOUND_CALL?
            {phoneNumber: hostPhoneCountryCode.split('-')[0] + ' ' + hostPhoneNumber}: null,
    };
  });

  return {
    ...(locations && !locations.length) ?
      {addOnType: ADD_ON_TYPE.OFFLINE} :
      null,
    location: formValues.location,
    addOnType: formValues.addOnType,
    locationConfigurations: modelLocationConfigs,
    meetingSettings: meetingSettings,
  };
};

/**
 * Generate final server appt model from form state and parameters
 * @param {*} formValues
 * @param {Array} segments
 * @param {String} apptType
 * @param {boolean} isCciAccount
 * @param {Object} meetingSettingDefaults
 * @return {Object}
 */
export const prepareServerApptPayload = (
  {apptType, formValues, isCciAccount, isManagedEvent, meetingSettingDefaults, segments}
) => {
  let submitSegs = [];
  let submitSegsRecurrence = {};
  submitSegs = prepareSegments(segments);
  if (apptType !== APPT_TYPE.CUSTOM) {
    submitSegsRecurrence = prepareRecurrenceSegments(
      formValues.segmentsRecurrence
    );
  }
  const durationValue = formDurationInputToModel(formValues.duration);
  const startTimeIncrementValue = formDurationInputToModel(formValues.startTimeIncrement);
  const slugEdited = formValues?.slug && formValues?.slug !== '';
  const repeatsForever = formValues.recurrenceRange.selectedRuleType === APPT_INTERVAL_TYPE.UNLIMITED;
  const recurrStartDt = repeatsForever ?
    getFormatTime(new Date()) :
    formValues.recurrenceRange.dateRange.startDate;

  // recurring appt end dateTime should always be one week after appt start dateTime
  const recurrEndDt = repeatsForever ?
    toDateTime(recurrStartDt)
      .plus({days: 7})
      .endOf('day').toISODate() :
    formValues.recurrenceRange.dateRange.endDate;

  const availabilityRulesAttendees = formValues?.attendees?.filter((attendee) => attendee.host !== false);

  const attendees = formAttendeesToModel(formValues, apptType);

  return ({
    secret: formValues.private,
    ignoreBusyEvent: formValues.ignoreBusyEvent,
    summary: formValues.summary,
    color: getSafeScheduleColorValue(formValues?.color),
    ...(formValues.capacity === 1 && (
      apptType === APPT_TYPE.RECURRING ||
      apptType === APPT_TYPE.CUSTOM)) ?
    {
      guestsAllowed: formValues.guestsAllowed,
    } : null,
    additionalHosts: formValues.additionalHosts,
    notificationType: formValues.notificationType,
    customNotifications: formCustomNotificationsToModel(formValues.notificationType, formValues.customNotifications),
    description: DOMPurify.sanitize(formValues.description),
    duration: durationValue,
    capacity: formValues?.capacity,
    confirmationPageType: formValues.confirmationPageType,
    ...formValues.confirmationPageType === REDIRECT_TYPE.EXTERNAL ?
    {
      redirectConfigurationAttributes: formValues.redirectConfigurationAttributes,
    } : null,
    anotherEventButtonEnabled: formValues.anotherEventButtonEnabled,
    ...formValues.anotherEventButtonEnabled ?
    {
      anotherEventButtonContent: formValues.anotherEventButtonContent,
    } : null,
    showCapacity: formValues.showCapacity,
    verify: formValues.verify,
    managelinksEnabled: formValues.managelinksEnabled,
    cancellationPolicy: formValues.cancellationPolicy,
    ...formValues.addOnType === ADD_ON_TYPE.OFFLINE ?
    {
      meetingSettings: {
        waitingRoom: false,
      },
    } :
    {
      meetingSettings: { // Use default value if setting is locked or PMI is used
        waitingRoom: (meetingSettingDefaults?.waitingRoomNew?.locked || meetingSettingDefaults?.usePMISchedule?.value) ?
          meetingSettingDefaults?.waitingRoomNew?.value :
          formValues.meetingSettings.waitingRoom,
      },
    },
    startTimeIncrement: isCciAccount ? durationValue : startTimeIncrementValue,
    customFields: formCustomFieldsToModel(formValues.customFields),
    cushion: formDurationInputToModel(formValues.cushionTime),
    bookingLimit: isCciAccount ? 0 : Number(formValues.bookingLimit.durationAmount),
    bookingLimitUnit: formValues.bookingLimit.durationUnit,
    noReply: formValues.noReply,
    buffer: isCciAccount ? {before: 0, after: 0} :
      {
        before: formDurationInputToModel(formValues.bufferTimeBefore),
        after: formDurationInputToModel(formValues.bufferTimeAfter),
      },
    creatorType: formValues.creatorType,
    attendees: attendees,
    // optionally include
    ...slugEdited ?
      {
        slug: formValues.slug,
      } : null,
    ...apptType !== APPT_TYPE.CUSTOM ? // Recurring - specific arguments
      {
        intervalType: repeatsForever ? APPT_INTERVAL_TYPE.UNLIMITED : APPT_INTERVAL_TYPE.FIXED,
        startDate: recurrStartDt,
        endDate: recurrEndDt,
      } : // Non-recurring - specific arguments
      {
        startDate: getFormatTime(submitSegs?.[0]?.start),
        endDate: getFormatTime(submitSegs?.[submitSegs.length - 1]?.end),
      },
    ...(apptType !== APPT_TYPE.CUSTOM && formValues.furthestDate?.selectedPeriodType !== PERIOD_TYPE.UNLIMITED) ?
      {
        maxBookingTime: daysToMinutes(formValues.furthestDate.durationAmount),
        periodType: formValues.furthestDate.durationUnit,
      } : null,
    ...(apptType !== APPT_TYPE.CUSTOM && formValues.furthestDate?.selectedPeriodType === PERIOD_TYPE.UNLIMITED) ?
      {
        periodType: PERIOD_TYPE.UNLIMITED,
      } : null,
    ...formPoolingStateToModel(formValues),
    ...isTeamApptType(apptType) && formValues.userPools.some((up) => up.poolingType === APPT_TYPE.ROUND_ROBIN) ?
      {
        reschedulePreference: formValues.reschedulePreference,
      } : null,
    ...formValues.availabilityOverride === false ? // null or undefined default to true
      {
        availabilityOverride: false,
        availabilityRules: availabilityRulesAttendees?.length ?
          availabilityRulesAttendees?.map((user) => {
            const rule = formValues.availabilityRules?.[virtualUserEmailToUid(user?.id?.toLowerCase())];
            const email = rule?.email?.toLowerCase() || user?.email?.toLowerCase();
            const segmentsRecurrence = rule ?
              prepareRecurrenceSegments(rule.segmentsRecurrence) :
              prepareRecurrenceSegments(getRecurringApptDefaultSegments());
            const segments = rule ?
              prepareSegments(rule.segments) :
              [];
            const timeZone = rule?.timeZone || formValues.timeZone;
            const useCustom = rule && rule.id ?
              rule.useCustom :
              true;
            return {
              email,
              segmentsRecurrence,
              segments,
              timeZone,
              useCustom,
              ...rule ? {id: rule.id} : null,
            };
          }) :
          Object.entries(formValues?.availabilityRules).map(([user, rule]) => ({
            email: isCciAccount? getZCCVirtualUserEmail() : (rule.email || getVirtualUserEmail(user)),
            useCustom: rule?.id ? rule.useCustom : true,
            id: rule.id,
            segmentsRecurrence: prepareRecurrenceSegments(rule.segmentsRecurrence),
            segments: prepareSegments(rule.segments),
            timeZone: rule?.timeZone || formValues.timeZone,
          })),
      } : { // formValues.availabilityOverride === true (team appts only) or undefined (backward compatibility)
        ...formValues.poolingType ? {
          availabilityOverride: true,
        } : null,
        ...apptType !== APPT_TYPE.CUSTOM ? {
          segmentsRecurrence: submitSegsRecurrence,
          segments: submitSegs,
          timeZone: formValues.timeZone,
        } : null,
      },
    ...isManagedEvent && {
      // Admin templates should NOT pass attendees
      attendees: undefined,
      assignedOrganizationMemberships: formValues?.assignedOrganizationMemberships,
      assignedUserGroups: formValues?.assignedUserGroups,
      owningGroup: formValues?.owningGroup,
    },
    ...formLocationConfigsToModel(apptType, formValues, meetingSettingDefaults),
  });
};
