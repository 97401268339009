import React, {
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';

import {nanoid} from '@reduxjs/toolkit';
import {useDispatch, useSelector} from 'react-redux';

import config from 'Config/config';
import {setCaptchaId} from 'Store/CaptchaStore';
import {isDevEnvironment, skipCaptchaTests} from 'Utils';

export const captchaResultType = {
  normal: 'normal',
  expired: 'expired',
  error: 'error',
  // custom result type, for grecaptcha unsupported event
  // when user dismisses captcha challenge window
  dismissed: 'dismissed',
};

const isDevEnv = isDevEnvironment();
const skipCaptcha = skipCaptchaTests();

// Function that initializes a MutationObserver for the captcha
// to detect if user clicks outside captcha challenge window
const initObserver = (onClickOutside) => {
  // Need to disable captcha modal dismissibility
  const domObserver = new MutationObserver(() => {
    const iframe = document.querySelector('iframe[src^="https://www.recaptcha.net/recaptcha"][src*="bframe"]');
    if (iframe) {
      domObserver.disconnect();

      const captchaBackground = iframe.parentNode?.parentNode?.firstChild;
      captchaBackground?.addEventListener('click', onClickOutside);
      // if (captchaBackground) {
      //   // Overwrite the captchaBackground click-away handler:
      //   captchaBackground.onclick = () => {};
      // }
    }
  });

  domObserver.observe(document.body || document.documentElement, {childList: true});
};

/**
 * @param {Object} recaptcha
 * @return {Object}
 */
function unwrapRecaptcha(recaptcha) {
  // enterprise
  if (!isDevEnv && !skipCaptcha) {
    return recaptcha.enterprise;
  }
  return recaptcha;
}

/**
 * @param {Object} props
 * @param {Object} ref
 * @return {ReactElement}
 */
function CaptchaDownload(props, ref) {
  const captchaId = useSelector((state) => state.captchaState.id);
  const dispatch = useDispatch();
  const recaptchaContainer = useRef();
  const resolveRef = useRef();
  const captchaInitialPromiseResolve = useRef();
  const captchaInitialPromiseReject = useRef();
  const queueExecutionRefs = useRef([]);
  const captchaInitialPromise = useRef(new Promise((resolve, reject) => {
    captchaInitialPromiseResolve.current = resolve;
    captchaInitialPromiseReject.current = reject;
  }));

  const onCaptchaExcuted = useCallback((response) => {
    // First queue item has completed, remove
    const completed = queueExecutionRefs.current.shift();
    console.log('captcha response', completed.id, response);
    resolveRef.current(response);

    // Execute the next captcha request in the queue
    const nextPromise = queueExecutionRefs.current[0];
    if (nextPromise) {
      console.log('captcha next', nextPromise.id);
      nextPromise.resolve(nextPromise.id);
    }
  }, []);

  const onCaptchaDismissed = useCallback(() => {
    onCaptchaExcuted({
      type: captchaResultType.dismissed,
    });
    const grecaptcha = unwrapRecaptcha(window.grecaptcha);
    grecaptcha.reset();
    initObserver(onCaptchaDismissed);
  }, [onCaptchaExcuted]);

  useImperativeHandle(ref, () => ({
    runCaptcha: () => new Promise((resolve, reject) => {
      // if grecaptcha is not initialized, waiting
      captchaInitialPromise.current
        .then(() => {
          const waitForCaptchaLock = new Promise((resolveLock, rejectLock) => {
            const runId = nanoid();
            console.log('captcha queueing', runId);

            queueExecutionRefs.current.push({
              id: runId,
              resolve: resolveLock,
              reject: rejectLock,
            });
            if (queueExecutionRefs.current.length === 1) {
              resolveLock(runId);
            }
          });
          return waitForCaptchaLock;
        })
        .then((executionId) => {
          resolveRef.current = resolve;
          const grecaptcha = unwrapRecaptcha(window.grecaptcha);
          grecaptcha.reset();
          initObserver(onCaptchaDismissed);
          console.log('captcha executing', executionId, captchaId);
          grecaptcha.execute(captchaId);
        })
        .catch((reason) => {
          reject(reason);
        });
    }),
    cookieTokenPromise: null,
    lastCookieTokenRefreshedTime: 0,
  }));

  useEffect(() => {
    window.__captchaOnload__ = () => {
      try {
        const grecaptcha = unwrapRecaptcha(window.grecaptcha);
        const id = grecaptcha.render(recaptchaContainer.current, {
          'sitekey': config.recaptchaSiteKey,
          'size': 'invisible',
          'badge': 'inline',
          'callback': (passcode) =>
            onCaptchaExcuted({
              type: captchaResultType.normal,
              payload: passcode,
            }),
          'expired-callback': () =>
            onCaptchaExcuted({
              type: captchaResultType.expired,
            }),
          'error-callback': () =>{
            onCaptchaExcuted({
              type: captchaResultType.error,
            });
          },
        });
        dispatch(setCaptchaId(id));
        initObserver(onCaptchaDismissed);
        captchaInitialPromiseResolve.current();
        window.__captchaOnload__ = undefined;
      } catch (e) {
        console.error('Error on captcha load', e);
        captchaInitialPromiseReject.current('Failed to initialize captcha');
      }
    };
    const script = document.createElement('script');
    script.src = `${config.recaptchaSource}?onload=__captchaOnload__`;
    document.head.append(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={recaptchaContainer}
      style={{
        display: 'none',
      }}
    ></div>
  );
}

export default forwardRef(CaptchaDownload);
