// children items content

import React, {useCallback, useEffect, useState} from 'react';

import {Drawer, Stack} from '@mui/material';

import {CustomMenu as StyleMenu} from '../ZmDropdown';

import {isMobile} from 'Utils/ZoomClientUtils';

export const CustomMenu = (props) => {
  const {children, content, onToggle, sx} = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (typeof onToggle === 'function') {
      onToggle(open);
    }
  }, [open, onToggle]);

  const handleOnClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const childrenCloned = React.cloneElement(children, {
    onClick: (e) => setAnchorEl(e.currentTarget),
  });

  return (
    <>
      {childrenCloned}
      <StyleMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleOnClose}
        sx={{
          '.MuiMenuItem-root': {padding: '4px 14px'},
          ...(sx ? sx : {}),
        }}
      >
        <Stack onClick={handleOnClose}>
          {content}
        </Stack>
      </StyleMenu>
    </>
  );
};

const CustomDrawer = (props) => {
  const {children, content, onToggle} = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (typeof onToggle === 'function') {
      onToggle(open);
    }
  }, [open, onToggle]);

  const handleOnClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const childrenCloned = React.cloneElement(children, {
    onClick: (e) => setAnchorEl(e.currentTarget),
  });

  return (
    <>
      {childrenCloned}
      <Drawer
        anchor="bottom"
        // anchorEl={anchorEl}
        open={open}
        onClose={handleOnClose}
      >
        <Stack onClick={handleOnClose}>
          {content}
        </Stack>
      </Drawer>
    </>
  );
};

const ZmMenu = isMobile ? CustomDrawer : CustomMenu;

export default ZmMenu;
