import {isDevEnvironment, skipCaptchaTests} from 'Utils';

const recaptchaSourceBase = 'https://www.recaptcha.net/recaptcha';

let recaptchaSiteKey = '6Lf2C54aAAAAAOOpnJT1sg39rowHN362Zj2QSyls';
let recaptchaSource = `${recaptchaSourceBase}/enterprise.js`;
if (isDevEnvironment()) {
  recaptchaSiteKey = '6LfRxwQgAAAAAD1Ok1oIKCpBJOcFVw4Y4ibIEaAF';
  recaptchaSource = `${recaptchaSourceBase}/api.js`;
}

const isTest = new URLSearchParams(window.location.search).get('testing') === 'true';
const skipCaptchas = skipCaptchaTests(isTest);
if (skipCaptchas) {
  // V2 Recaptcha site key for purposes of automated browser testing
  // https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha.-what-should-i-do
  recaptchaSiteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
  recaptchaSource = `${recaptchaSourceBase}/api.js`;
}

const config = {
  recaptchaSource,
  recaptchaSiteKey,
  maxCallCaptchaCount: 3,
};

export default config;
