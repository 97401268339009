import {createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit';

import {
  CALENDAR_TEMPLATE, PRESET_TYPES,
  SYSTEM_CALENDAR_WORKFLOWS, SYSTEM_EMAIL_WORKFLOWS,
  SYSTEM_INDIVIDUAL_WORKFLOWS, SYSTEM_WORKFLOWS,
} from '../Components/WorkflowsList/utils';
import {merge} from '../Utils';

import {
  createWorkflow,
  deleteWorkflow,
  listMessageTemplates,
  listPresetWfTemplates,
  listWorkflows,
  patchWorkflow,
} from 'Api/ZCalendar';
import {WF_METHOD, WF_TYPE} from 'Utils/consts';

const initialState = {
  workflows: [],
  msgTemplates: {
    list: [],
    isLoading: false,
    errorState: {
      isError: false,
      reason: '',
    },
  },
  wfTemplates: {
    list: [],
    isLoading: false,
    errorState: {
      isError: false,
      reason: '',
    },
  },
  isLoading: false,
  errorState: {
    isError: false,
    reason: '',
  },
  isWorkflowFormOpen: false,
};

export const createDefaultWorkflow = (wf, templates) => {
  if (wf.id) {
    return wf;
  }
  if (wf.email) {
    const workflowEmails = wf.email;
    // Workaround: Server wf template email fields may have entries with no actual matching email template
    // Need to filter such emails out
    const wfEmailsWithData = workflowEmails.filter((email) => {
      return (templates.some(
        (item) => item.variant === email.variant && item.method === WF_METHOD.EMAIL && item.receiver === email.receiver)
      );
    });
    const workflow = {...wf, email: wfEmailsWithData};
    return workflow;
  }
  if (wf.text) {
    const workflowTexts = wf.text;
    // Workaround: Server wf template sms fields may have entries with no actual matching sms template
    // Need to filter such sms texts out
    const wfTextsWithData = workflowTexts.filter((text) => {
      return (templates.some(
        (item) => item.variant === text.variant && item.method === WF_METHOD.TEXT && item.receiver === text.receiver)
      );
    });
    const workflow = {...wf, text: wfTextsWithData};
    return workflow;
  }
};

const shouldAddWorkflowTemplate = (workflows, wfTemplate) => {
  if (wfTemplate.type === WF_TYPE.CUSTOM) {
    return false;
  }
  return !workflows.find((workflow) => {
    return (workflow.type === wfTemplate.type &&
      !!workflow.isSystem === !!wfTemplate.isSystem);
  });
};

const addMissingPresets = (createdWorkflows, workflowTemplates, isCciAccount, includeSystem=true) => {
  const expandedWorkflows = [];
  if (includeSystem) {
    expandedWorkflows.push(...SYSTEM_WORKFLOWS);
  }
  expandedWorkflows.push(...createdWorkflows);
  for (const workflowTemplate of workflowTemplates) {
    if (workflowTemplate.isSystem ||
        shouldAddWorkflowTemplate(createdWorkflows, workflowTemplate)) {
      expandedWorkflows.push({
        ...workflowTemplate,
        email: isCciAccount ?
          workflowTemplate?.email?.filter((item) => item.receiver === 'booker') :
          workflowTemplate.email,
        text: isCciAccount ?
          workflowTemplate?.text?.filter((item) => item.receiver === 'booker') :
          workflowTemplate.text,
      });
    }
  }
  return expandedWorkflows;
};

/**
 * Return the enabled notification settings depending on org profile + app flag
 * @param {*} state
 * @return {{email: boolean, text: boolean}}
 */
export const selectEnabledNotifMethods = createSelector(
  [
    (state) => state.organizationSettingState.emailNotificationEnabled,
    (state) => state.organizationSettingState.smsNotificationEnabled,
    (state) => state.hostSettingsState.isSmsLimited,
    (state) => state.organizationSettingState.isSmsLimited,
  ],
  (emailEnabled, smsEnabled, isUserSmsLimited, isOrgSmsLimited) => {
    return ({
      [WF_METHOD.EMAIL]: emailEnabled !== false &&
        process.env.REACT_APP_ENABLE_EMAIL === 'true',
      [WF_METHOD.TEXT]: smsEnabled !== false && isUserSmsLimited !== true &&
      isOrgSmsLimited !== true && process.env.REACT_APP_ENABLE_SMS === 'true',
    });
  }
);

/**
 * Gets the expanded list of server workflows + un-created workflow presets
 * @param {*} state
 * @param {boolean} isCciAccount
 * @return {Workflow[]}
 */
export const selectWorkflowsAndPresets = createSelector(
  [
    (state) => state.hostWorkflowsState.workflows,
    (state) => state.hostWorkflowsState.wfTemplates.list,
    (state, isCciAccount) => isCciAccount,
  ],
  (workflows, templatesList, isCciAccount) => {
    return addMissingPresets(
      workflows,
      templatesList,
      isCciAccount,
    );
  }
);

export const selectApptNotificationDefaults = (state) => {
  const wfs = [
    ...SYSTEM_EMAIL_WORKFLOWS,
    ...SYSTEM_CALENDAR_WORKFLOWS,
    ...SYSTEM_INDIVIDUAL_WORKFLOWS,
  ];

  const results = {};
  wfs.forEach((wf) => {
    const res = {
      subject: '',
      body: '',
    };
    if (!wf.isSystem) {
      // add appt notification reminder fields
      res.enabled = wf.enabled;
      res.minutes = wf.minutes;
    }
    const wfActionMethodType = wf.email.length ? WF_METHOD.EMAIL : WF_METHOD.TEXT;
    const wfAction = wf[wfActionMethodType][0];
    const templates = state.hostWorkflowsState.msgTemplates.list;
    for (let i = 0; i < templates.length; i++) {
      const template = templates[i];
      if (template.receiver === wfAction.receiver && template.method === wfActionMethodType) {
        if (template.variant === wfAction.variant) {
          res.subject = template.subject;
          res.body = template.body;
        }
      }
    }
    results[wf.type] = res;
  });

  return {
    email: {
      attendeeConfirmation: results.confirmation,
      attendeeCancellation: results.cancellation,
    },
    calendar: {
      attendeeInvitation: results.calendarInvitation,
    },
    attendeeEmailReminder: results.attendeeEmailReminder,
    attendeeSmsReminder: results.attendeeSmsReminder,
    attendeeEmailFollowUp: results.attendeeEmailFollowUp,
  };
};


export const addWorkflowForCalendar = createAsyncThunk(
  'hostWorkflows/addByCalendar',
  async ({calendarId, data}, thunkAPI) => {
    const enabledNotifMethods = selectEnabledNotifMethods(thunkAPI.getState());
    if (!enabledNotifMethods.email && data['email']) {
      delete data['email'];
    }
    if (!enabledNotifMethods.text && data['text']) {
      delete data['text'];
    }
    const response = await createWorkflow(calendarId, data);
    return response;
  }
);

export const listWorkflowsForCalendar = createAsyncThunk(
  'hostWorkflows/listByCalendar',
  async ({calendarId, query}, thunkAPI) => {
    const response = await listWorkflows(calendarId, query);
    return response;
  }
);

export const listMsgTemplates = createAsyncThunk(
  'hostWorkflows/listMsgTemplates',
  async (listTemplatesQuery, thunkAPI) => {
    const response = await listMessageTemplates(listTemplatesQuery);
    return response;
  }
);

export const listWfTemplates = createAsyncThunk(
  'hostWorkflows/listWfTemplates',
  async (listTemplatesQuery, thunkAPI) => {
    const response = await listPresetWfTemplates(listTemplatesQuery);
    return response;
  }
);

export const editWorkflowForCalendar = createAsyncThunk(
  'hostWorkflows/editByCalendar',
  async ({calendarId, data, workflowId}, thunkAPI) => {
    const enabledNotifMethods = selectEnabledNotifMethods(thunkAPI.getState());
    if (!enabledNotifMethods.email && data['email']) {
      delete data['email'];
    }
    if (!enabledNotifMethods.text && data['text']) {
      delete data['text'];
    }
    const response = await patchWorkflow(workflowId, calendarId, data);
    return response;
  }
);

export const deleteWorkflowForCalendar = createAsyncThunk(
  'hostWorkflows/deleteByCalendar',
  async ({calendarId, workflowId}, thunkAPI) => {
    const response = await deleteWorkflow(calendarId, workflowId);
    return response;
  }
);

export const updateApptWorkflows = createAsyncThunk(
  'hostWorkflows/updateApptWorkflows',
  async ({appointmentId, calendarId, msgTemplates, newWfIds}, thunkAPI) => {
    const currState = thunkAPI.getState();
    const workflows = currState.hostWorkflowsState.workflows;
    const wfTemplates = currState.hostWorkflowsState.wfTemplates.list;
    const promisedArr = [];
    workflows.forEach(async (currWorkflow) => {
      if (!currWorkflow.appointmentId?.includes(appointmentId) && newWfIds.includes(currWorkflow.id)) {
        // Add appointment id to current workflow
        try {
          const updatePromise = patchWorkflow(
            currWorkflow.id,
            calendarId,
            {
              appointmentId: [...currWorkflow.appointmentId, appointmentId],
            },
          );
          promisedArr.push(updatePromise);
        } catch (e) {
          console.log('failure adding to workflow');
        }
      } else if (currWorkflow.appointmentId?.includes(appointmentId) && !newWfIds.includes(currWorkflow.id)) {
        // Remove appointment id from current workflow
        try {
          const removed = currWorkflow.appointmentId.filter((element) => element !== appointmentId.toString());
          const updatePromise = patchWorkflow(
            currWorkflow.id,
            calendarId,
            {
              appointmentId: removed,
            },
          );
          promisedArr.push(updatePromise);
          // await dispatch(updateWorkflow(updated)));
        } catch (e) {
          console.log('failure to remove from workflow');
        }
      }
    });
    // Create new workflow from template
    newWfIds.forEach((wfId) => {
      // Created workflow ids are id strings like 'ab5331dfafefega'
      // Not-yet-created workflow template ids will be the wf.type like confirmation, reminder, etc.
      if (PRESET_TYPES.has(wfId)) {
        const currentTemplate = wfTemplates.find((template) => (
          !template.isSystem && (template.type === wfId)
        ));
        if (currentTemplate) {
          const wfPayload = {
            ...createDefaultWorkflow(currentTemplate, msgTemplates),
            appointmentId: [appointmentId],
          };
          const enabledNotifMethods = selectEnabledNotifMethods(thunkAPI.getState());
          if (!enabledNotifMethods.email && wfPayload['email']) {
            delete wfPayload['email'];
          }
          if (!enabledNotifMethods.text && wfPayload['text']) {
            delete wfPayload['text'];
          }
          try {
            const createPromise = createWorkflow(
              calendarId,
              wfPayload,
            );
            promisedArr.push(createPromise);
          } catch (e) {
            console.log('failure creating workflow from template');
          }
        } else {
          console.log('unrecognized template id', wfId);
        }
      }
    });
    const settledArr = Promise.allSettled(promisedArr);
    return settledArr;
  }
);


export const hostWorkflowsStore = createSlice({
  name: 'HostWorkflowsStore',
  initialState,
  reducers: {
    setWorkflowFormOpen: (state, action) => {
      state.isWorkflowFormOpen = action.payload;
    },
    addWorkflow: (state, action) => {
      state.workflows.push(action.payload);
    },
    updateWorkflow: (state, action) => {
      const i = state.workflows.findIndex((wf) => wf.id === action.payload.id);
      if (i >= 0) {
        state.workflows[i] = action.payload;
      } else {
        console.error('Redux failed to update workflow, no matching id');
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listWorkflowsForCalendar.fulfilled, (state, action) => {
      state.isLoading = false;
      const workflows = action.payload.items;
      state.workflows = workflows;
      state.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(listWorkflowsForCalendar.pending, (state, action) => {
      state.isLoading = true;
      state.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(listWorkflowsForCalendar.rejected, (state, action) => {
      state.isLoading = false;
      state.errorState = {
        isError: true,
        reason: 'storeErrors.listWorkflowsForCalendar',
      };
    });

    builder.addCase(deleteWorkflowForCalendar.fulfilled, (state, action) => {
      state.workflows = state.workflows.filter((workflow) => action.meta.arg.workflowId !== workflow.id);
    });
    builder.addCase(deleteWorkflowForCalendar.rejected, (state, action) => {
      console.error('Error with deleting workflows ', action.error);
    });

    builder.addCase(updateApptWorkflows.fulfilled, (state, action) => {
      const workflows = action.payload
        .filter((promise) => promise.status === 'fulfilled')
        .map((promise) => promise.value);
      state.workflows = merge(state.workflows, workflows, 'id');
      const failedWorkflows = action.payload.filter((promise) => promise.status === 'rejected');
      if (failedWorkflows.length > 0) {
        console.error(`Failed to update ${failedWorkflows.length} workflows`);
      }
    });
    builder.addCase(updateApptWorkflows.rejected, (state, action) => {
      console.error('Error with updating workflows ', action.error);
    });

    builder.addCase(listMsgTemplates.fulfilled, (state, action) => {
      state.msgTemplates.isLoading = false;
      // Temporary fix: Add artificial UI-only template for calendar invitation notification
      state.msgTemplates.list = [...action.payload.templates, {
        ...CALENDAR_TEMPLATE,
      }];
      state.msgTemplates.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(listMsgTemplates.pending, (state, action) => {
      state.msgTemplates.isLoading = true;
      state.msgTemplates.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(listMsgTemplates.rejected, (state, action) => {
      state.msgTemplates.isLoading = false;
      state.msgTemplates.errorState = {
        isError: true,
        reason: 'storeErrors.listTemplatesForWorkflows',
      };
    });

    builder.addCase(listWfTemplates.fulfilled, (state, action) => {
      state.wfTemplates.isLoading = false;
      state.wfTemplates.list = action.payload.templates;
      state.wfTemplates.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(listWfTemplates.pending, (state, action) => {
      state.wfTemplates.isLoading = true;
      state.wfTemplates.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(listWfTemplates.rejected, (state, action) => {
      state.wfTemplates.isLoading = false;
      state.wfTemplates.errorState = {
        isError: true,
        reason: 'storeErrors.listTemplatesForWorkflows',
      };
    });
  },
});

export const {addWorkflow, setWorkflowFormOpen, updateWorkflow} = hostWorkflowsStore.actions;

export default hostWorkflowsStore.reducer;
