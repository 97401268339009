import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import {httpErrorSerializer} from './storeUtils';

import {
  fetchUserSlugSetting,
  listHostPhones,
  patchHostPhones,
  patchUserSlugSetting,
  getProfilePicture,
  getMeetingSettings,
  patchProfilePicture,
  patchUserProfilePicSyncLock,
  patchUserProfileNameSyncLock,
  getDisplayName,
  patchDisplayNameSetting,
  getSchedules,
  createSchedule,
  patchSchedule,
  deleteSchedule,
  postDefaultSchedule,
  getAppointmentsForAvailability,
  patchActiveAppts,
  getUserProfilePicSyncLock,
  getUserProfileNameSyncLock,
  patchDateFormatSetting,
  patchTimeFormatSetting,
  getDateFormatSetting,
  getTimeFormatSetting,
  uploadFileToZFS,
  getNotificationEmailSetting,
  patchNotificationEmailSetting,
  getPersonalSmsLimit,
} from 'Api/ZCalendar';
import {HttpError} from 'Error/HttpError';
import {getTimeFormat, strEq} from 'Utils';
import {getUserInfo} from 'Utils/integration';
// import {merge} from 'Utils';

export const PICTURE_EMPTY = ''; // If a profile picture was set and deleted

export const initialState = {
  isLoading: false,
  errorState: {
    isError: false,
    reason: '',
  },
  phoneNumbers: [],
  hasLoaded: false,
  slug: {
    errorState: {
      isError: false,
      reason: '',
    },
    value: '',
    slugFormatEnabled: false, // admin set slug format
    hasLoaded: false,
  },
  slugs: {},
  displayName: {
    errorState: {
      isError: false,
      reason: '',
    },
    id: '',
    value: '',
    adminProfileSyncLocked: true,
    isLoading: false,
    hasLoaded: false,
  },
  profilePicture: {
    errorState: {
      isError: false,
      reason: '',
    },
    id: '',
    value: {
      data: '',
    },
    adminProfileSyncLocked: true,
    isLoading: false,
    hasLoaded: false,
  },
  meetingSettings: {
    errorState: {
      isError: false,
      reason: '',
    },
    value: {
      waitingRoom: false,
    },
    isLoading: false,
    hasLoaded: false,
  },
  dateFormat: {
    errorState: {
      isError: false,
      reason: '',
    },
    value: '',
    isLoading: false,
  },
  timeFormat: {
    errorState: {
      isError: false,
      reason: '',
    },
    value: getTimeFormat()?.showAMPM === false ? '24h' : '12h',
    isLoading: false,
    hasLoaded: false,
  },
  notificationEmail: {
    errorState: {
      isError: false,
      reason: '',
    },
    value: '',
    isLoading: false,
  },
  schedules: {
    errorState: {
      isError: false,
      reason: '',
    },
    value: [],
    isLoading: false,
    hasLoaded: false,
  },
  appointments: {
    errorState: {
      isError: false,
      reason: '',
    },
    value: [],
    isLoading: false,
    hasLoaded: false,
  },
  userProfilePicSyncLock: undefined,
  userProfileNameSyncLock: undefined,
  hasFixedBottomDialog: false,
  walkthrough: false,
  walkthroughCompleted: false,
  walkthroughStep: 0,
  uploadBinaryImageLoading: false,
  // user-level and read-only attribute. server will ban the SMS function of suspicious user.
  isSmsLimited: false,
};

export const fetchUserProfilePicSyncLockSetting = createAsyncThunk(
  'hostSettings/fetchUserProfilePicSyncLock',
  async () => {
    const response = await getUserProfilePicSyncLock();
    return response;
  }
);

export const fetchUserProfileNameSyncLockSetting = createAsyncThunk(
  'hostSettings/fetchUserProfileNameSyncLock',
  async () => {
    const response = await getUserProfileNameSyncLock();
    return response;
  }
);

export const listSchedules = createAsyncThunk(
  'hostSettings/listSchedules',
  /**
   * @param {Object} args
   * @return {Promise}
   */
  async (args) => {
    const response = await getSchedules(args?.query);
    return response;
  }
);

export const addSchedule = createAsyncThunk(
  'hostSettings/addSchedule',
  /**
   * @param {Object} data
   * @return {Promise}
   */
  async (data) => {
    const response = await createSchedule(data);
    return response;
  }
);

export const editSchedule = createAsyncThunk(
  'hostSettings/editSchedule',
  async ({availabilityId, data}, thunkAPI) => {
    const response = await patchSchedule(availabilityId, data);
    return response;
  },
  {serializeError: httpErrorSerializer},
);

export const removeSchedule = createAsyncThunk(
  'hostSettings/removeSchedule',
  async ({availabilityId, data}, thunkAPI) => {
    const response = await deleteSchedule(availabilityId, data);
    return response;
  },
  {serializeError: httpErrorSerializer},
);

export const setDefaultSchedule = createAsyncThunk(
  'hostSettings/setDefaultSchedule',
  async ({data, newDefaultId}, thunkAPI) => {
    const response = await postDefaultSchedule(newDefaultId, data);
    return response;
  },
  {serializeError: httpErrorSerializer},
);

export const listAppointmentsForAvailability = createAsyncThunk(
  'hostSettings/listAppointmentsForAvailability',
  /**
   * @param {Object} args
   * @return {Promise}
   */
  async (args) => {
    const response = await getAppointmentsForAvailability(args?.query);
    return response;
  },
  {serializeError: httpErrorSerializer},
);

export const editActiveAppts = createAsyncThunk(
  'hostSettings/editActiveAppts',
  async ({availabilityId, data}, thunkAPI) => {
    const response = await patchActiveAppts(availabilityId, data);
    return response;
  },
  {serializeError: httpErrorSerializer},
);

export const listProfile = createAsyncThunk(
  'hostSettings/getProfilePicture',
  async () => {
    return await getProfilePicture();
  },
  {serializeError: httpErrorSerializer},
);

export const patchProfile = createAsyncThunk(
  'hostSettings/patchProfilePicture',
  async ({data}, thunkAPI) => {
    await patchUserProfilePicSyncLock('true');
    const response = await patchProfilePicture({
      id: 'picture',
      value: {
        data,
      },
    });
    return response;
  },
  {serializeError: httpErrorSerializer},
);

export const fetchDisplayName = createAsyncThunk(
  'hostSettings/getDisplayName',
  async () => {
    return await getDisplayName();
  },
  {serializeError: httpErrorSerializer},
);

export const fetchNotificationEmail = createAsyncThunk(
  'hostSettings/fetchNotificationEmail',
  async () => {
    return await getNotificationEmailSetting();
  },
  {serializeError: httpErrorSerializer},
);

export const patchNotificationEmail = createAsyncThunk(
  'hostSettings/patchNotificationEmail',
  async (value) => {
    return await patchNotificationEmailSetting({
      id: 'notificationEmail',
      value,
    });
  },
  {serializeError: httpErrorSerializer},
);

export const fetchDateFormat = createAsyncThunk(
  'hostSettings/getDateFormatSetting',
  async () => {
    return await getDateFormatSetting();
  },
  {serializeError: httpErrorSerializer},
);

export const patchDateFormat = createAsyncThunk(
  'hostSettings/patchDateFormat',
  async (value) => {
    return await patchDateFormatSetting({
      id: 'dateFormat',
      value,
    });
  },
  {serializeError: httpErrorSerializer},
);

export const fetchTimeFormat = createAsyncThunk(
  'hostSettings/getTimeFormatSetting',
  async () => {
    return await getTimeFormatSetting();
  },
  {serializeError: httpErrorSerializer},
);

export const patchTimeFormat = createAsyncThunk(
  'hostSettings/patchTimeFormat',
  async (value) => {
    return await patchTimeFormatSetting({
      id: 'timeFormat',
      value,
    });
  },
  {serializeError: httpErrorSerializer},
);

export const patchDisplayName = createAsyncThunk(
  'hostSettings/patchDisplayName',
  async ({value}) => {
    await patchUserProfileNameSyncLock('true');
    return await patchDisplayNameSetting(value);
  },
  {serializeError: httpErrorSerializer},
);

export const patchUserSyncLock = createAsyncThunk(
  'hostSettings/patchUserProfileSyncLock',
  async ({type, value}, thunkAPI) => {
    if (type === 'pic') {
      await patchUserProfilePicSyncLock(value);
      thunkAPI.dispatch(listProfile());
    } else if (type === 'name') {
      await patchUserProfileNameSyncLock(value);
      thunkAPI.dispatch(fetchDisplayName());
    }
  }
);

export const getMeetingDefaults = createAsyncThunk(
  'hostSettings/getMeetingSettings',
  async () => {
    return await getMeetingSettings();
  }
);

export const getUserSmsLimit = createAsyncThunk(
  'hostSettings/getUserSmsLimit',
  async () => {
    return await getPersonalSmsLimit();
  }
);

export const selectIsNoPhoneSet = (state) => {
  return state.hostSettingsState.phoneNumbers?.length < 1 && state.hostSettingsState.hasLoaded;
};

export const listPhoneNumbers = createAsyncThunk(
  'hostSettings/listPhoneNumbers',
  async () => {
    try {
      const response = await listHostPhones();
      return response;
    } catch (e) {
      console.error(e);
      // When the user has never set the phone number before,
      // calendar will return 404 error fetching the setting.
      // Treat 404 as a successful, empty response
      if (e instanceof HttpError) {
        if (e.errorCode === 404) {
          return {
            id: 'phoneNumbers',
            value: [],
          };
        }
      }
      throw e;
    }
  },
  {serializeError: httpErrorSerializer},
);

export const addPhoneNumber = createAsyncThunk(
  'hostSettings/addPhoneNumber',
  async ({
    phoneNumber,
    verificationCode,
  }, thunkAPI) => {
    const currentPhones = thunkAPI.getState().hostSettingsState.phoneNumbers
      .map((entry) => omit(entry, 'primary'));
    const response = await patchHostPhones({
      id: 'phoneNumbers',
      value: [
        ...currentPhones,
        {
          phoneNumber: phoneNumber,
          verificationCode: verificationCode,
          primary: true,
        },
      ],
    });
    return response;
  },
  {serializeError: httpErrorSerializer},
);

export const editPhoneNumber = createAsyncThunk(
  'hostSettings/editPhoneNumber',
  async ({
    newPhoneNumber,
    prevPhoneNumber,
    verificationCode,
  }, thunkAPI) => {
    const currentPhones = thunkAPI.getState().hostSettingsState.phoneNumbers
      .map((entry) => omit(entry, 'primary'));

    const editingPhoneEntry = currentPhones.findIndex((item) => item.phoneNumber === prevPhoneNumber);
    currentPhones.splice(editingPhoneEntry, 1, {
      phoneNumber: newPhoneNumber,
      verificationCode: verificationCode,
      primary: true,
    });

    const response = await patchHostPhones({
      id: 'phoneNumbers',
      value: currentPhones,
    });
    return response;
  },
  {serializeError: httpErrorSerializer},
);

export const switchPrimaryPhone = createAsyncThunk(
  'hostSettings/switchPrimaryPhoneNumber',
  async ({
    phoneNumber,
  }, thunkAPI) => {
    const currentPhones = thunkAPI.getState().hostSettingsState.phoneNumbers;
    const newPhones = currentPhones
      .map((entry) => {
        if (entry.phoneNumber === phoneNumber) {
          return {
            ...entry,
            primary: true,
          };
        }
        return omit(entry, 'primary');
      });

    const response = await patchHostPhones({
      id: 'phoneNumbers',
      value: newPhones,
    });
    return response;
  },
  {serializeError: httpErrorSerializer},
);

export const deletePhone = createAsyncThunk(
  'hostSettings/deletePhoneNumber',
  async ({
    phoneNumber,
  }, thunkAPI) => {
    const currentPhones = thunkAPI.getState().hostSettingsState.phoneNumbers;
    let newPhones = currentPhones
      .filter((entry) => entry.phoneNumber !== phoneNumber);

    const primaryDeleted = !newPhones.some((phoneItem) => phoneItem.primary);
    // If the deleted phone was the primary, set the first available as primary if exists
    if (primaryDeleted) {
      newPhones = newPhones.map((entry, i) => {
        if (i === 0) {
          return {...entry, primary: true};
        }
        return {...entry};
      });
    }

    const response = await patchHostPhones({
      id: 'phoneNumbers',
      value: newPhones,
    });
    return response;
  },
  {serializeError: httpErrorSerializer},
);

export const getUserSlug = createAsyncThunk(
  'hostSettings/getUserSlug',
  async (query) => {
    return await fetchUserSlugSetting(query);
  },
  {serializeError: httpErrorSerializer},
);

export const patchUserSlug = createAsyncThunk(
  'hostSettings/patchUserSlug',
  async ({value}) => {
    return await patchUserSlugSetting(value);
  },
  {serializeError: httpErrorSerializer},
);

export const uploadBinaryImage = createAsyncThunk(
  'hostSettings/uploadBinaryImage',
  async ({file, maxSizeInMB = 1}, thunkAPI) => {
    const filesize = ((file.size/1024)/1024).toFixed(4); // get the filesize in MB
    if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
      return thunkAPI.rejectWithValue({
        msg: 'branding.uploaderErrorFileType',
      });
    }
    if (filesize > maxSizeInMB) {
      return thunkAPI.rejectWithValue({
        msg: 'branding.uploaderErrorSize',
        params: {size: maxSizeInMB},
      });
    }
    try {
      return await uploadFileToZFS(file);
    } catch (error) {
      return thunkAPI.rejectWithValue({
        msg: 'common.generalFailureTip',
      });
    }
  },
  {serializeError: httpErrorSerializer},
);

export const hostSettingsStore = createSlice({
  name: 'hostSettingsStore',
  initialState,
  reducers: {
    setHasFixedBottomDialog(state, action) {
      state.hasFixedBottomDialog = action.payload;
    },
    setTimeFormat(state, action) {
      state.timeFormat.value = action.payload;
    },
    setWalkthrough(state, action) {
      state.walkthrough = action.payload;
    },
    setWalkthroughCompleted(state, action) {
      state.walkthroughCompleted = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(patchUserSyncLock.pending, (state, action) => {
    // state.profilePicture.isLoading = true;
    // state.displayName.isLoading = true;
    // });
    builder.addCase(patchUserSyncLock.fulfilled, (state, action) => {
      const type = action.meta.arg['type'];
      const value = action.meta.arg['value'];
      if (type === 'pic') {
        state.userProfilePicSyncLock = value;
      } else if (type === 'name') {
        state.userProfileNameSyncLock = value;
      }
      // state.profilePicture.isLoading = false;
      // state.displayName.isLoading = false;
      // state.profilePicture.value = action.payload.profilePicture.value;
      // state.displayName.value = action.payload.displayName.value;
    });
    // builder.addCase(patchUserSyncLock.rejected, (state, action) => {
    // state.profilePicture.isLoading = false;
    // state.displayName.isLoading = false;
    // });

    builder.addCase(listProfile.pending, (state, action) => {
      state.profilePicture.isLoading = true;
      state.profilePicture.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(listProfile.fulfilled, (state, action) => {
      state.profilePicture.value = action.payload.value;
      state.profilePicture.adminProfileSyncLocked = action.payload.adminProfileSyncLocked;
      state.profilePicture.isLoading = false;
      state.profilePicture.hasLoaded = true;
    });
    builder.addCase(listProfile.rejected, (state, action) => {
      state.profilePicture.isLoading = false;
      // if (action.error?.errorCode === 500 && isInvalidPictureWeb(action.error?.message)) {
      //   state.profilePicture.hasLoaded = true;
      // }
      state.profilePicture.errorState = {
        isError: true,
        reason: action.error?.message,
      };
    });

    builder.addCase(patchProfile.pending, (state, action) => {
      state.profilePicture.isLoading = true;
      state.profilePicture.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(patchProfile.fulfilled, (state, action) => {
      state.profilePicture.isLoading = false;
      state.profilePicture.value = action.payload.value;
    });
    builder.addCase(patchProfile.rejected, (state, action) => {
      state.profilePicture.isLoading = false;
    });

    builder.addCase(fetchDisplayName.pending, (state, action) => {
      state.displayName.isLoading = true;
    });
    builder.addCase(fetchDisplayName.fulfilled, (state, action) => {
      state.displayName.value = action.payload.value;
      state.displayName.adminProfileSyncLocked = action.payload.adminProfileSyncLocked;
      state.displayName.isLoading = false;
      state.displayName.hasLoaded = true;
      state.displayName.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(fetchDisplayName.rejected, (state, action) => {
      state.displayName.isLoading = false;
      state.displayName.errorState = {
        isError: true,
        reason: action.error?.message,
      };
    });

    builder.addCase(fetchUserProfilePicSyncLockSetting.fulfilled, (state, action) => {
      state.userProfilePicSyncLock = action.payload.value;
    });
    builder.addCase(fetchUserProfileNameSyncLockSetting.fulfilled, (state, action) => {
      state.userProfileNameSyncLock = action.payload.value;
    });

    builder.addCase(patchDisplayName.pending, (state, action) => {
      state.displayName.isLoading = true;
    });
    builder.addCase(patchDisplayName.fulfilled, (state, action) => {
      state.displayName.isLoading = false;
      state.displayName.value = action.payload.value;
    });
    builder.addCase(patchDisplayName.rejected, (state, action) => {
      state.displayName.isLoading = false;
    });

    builder.addCase(fetchNotificationEmail.pending, (state, action) => {
      state.notificationEmail.isLoading = true;
      state.notificationEmail.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(fetchNotificationEmail.fulfilled, (state, action) => {
      state.notificationEmail.value = action.payload.value;
      state.notificationEmail.isLoading = false;
    });
    builder.addCase(fetchNotificationEmail.rejected, (state, action) => {
      state.notificationEmail.isLoading = false;
      state.notificationEmail.errorState = {
        isError: true,
        reason: 'storeErrors.fetchNotificationEmail',
      };
    });
    builder.addCase(patchNotificationEmail.pending, (state, action) => {
      state.notificationEmail.isLoading = true;
    });
    builder.addCase(patchNotificationEmail.fulfilled, (state, action) => {
      state.notificationEmail.isLoading = false;
      state.notificationEmail.value = action.payload.value;
    });
    builder.addCase(patchNotificationEmail.rejected, (state, action) => {
      state.notificationEmail.isLoading = false;
    });

    builder.addCase(fetchDateFormat.pending, (state, action) => {
      state.dateFormat.isLoading = true;
    });
    builder.addCase(fetchDateFormat.fulfilled, (state, action) => {
      state.dateFormat.value = action.payload.value;
      state.dateFormat.isLoading = false;
      state.dateFormat.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(getUserSmsLimit.fulfilled, (state, action) => {
      state.isSmsLimited = action.payload.value === 'true';
    });
    builder.addCase(fetchDateFormat.rejected, (state, action) => {
      state.dateFormat.isLoading = false;
      state.dateFormat.errorState = {
        isError: true,
        reason: 'storeErrors.fetchDateFormat',
      };
    });

    builder.addCase(patchDateFormat.pending, (state, action) => {
      state.dateFormat.isLoading = true;
    });
    builder.addCase(patchDateFormat.fulfilled, (state, action) => {
      state.dateFormat.isLoading = false;
      state.dateFormat.value = action.payload.value;
    });
    builder.addCase(patchDateFormat.rejected, (state, action) => {
      state.dateFormat.isLoading = false;
    });

    builder.addCase(fetchTimeFormat.pending, (state, action) => {
      state.timeFormat.isLoading = true;
    });
    builder.addCase(fetchTimeFormat.fulfilled, (state, action) => {
      state.timeFormat.value = action.payload.value;
      state.timeFormat.isLoading = false;
      state.timeFormat.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(fetchTimeFormat.rejected, (state, action) => {
      state.timeFormat.isLoading = false;
      state.timeFormat.errorState = {
        isError: true,
        reason: 'storeErrors.fetchTimeFormat',
      };
    });

    builder.addCase(patchTimeFormat.pending, (state, action) => {
      state.timeFormat.isLoading = true;
    });
    builder.addCase(patchTimeFormat.fulfilled, (state, action) => {
      state.timeFormat.isLoading = false;
      state.timeFormat.value = action.payload.value;
    });
    builder.addCase(patchTimeFormat.rejected, (state, action) => {
      state.timeFormat.isLoading = false;
    });

    builder.addCase(listSchedules.pending, (state, action) => {
      state.schedules.isLoading = true;
      state.schedules.errorState.isError = false;
    });
    builder.addCase(listSchedules.fulfilled, (state, action) => {
      const user = action.meta.arg?.query?.user ?? getUserInfo()?.calendarId;
      if (typeof user !== 'string' && user) {
        /** @type {string[]} calendarIds array */
        if (user?.length === 1) {
          state.schedules.value = [
            ...state.schedules.value.filter((availability) => !strEq(availability?.owner, user[0])),
            ...action.payload?.items,
          ];
        } else {
          const userSchedEmails = user.map((u) => u.toLowerCase());
          // batch-queried user availabilities
          userSchedEmails.forEach((batchUser) => {
            const batchUserResp = action.payload?.[batchUser];
            if (!batchUserResp || batchUserResp.error) {
              return;
            }
            state.schedules.value = [
              ...state.schedules.value.filter((availability) => !strEq(availability?.owner, batchUser)),
              ...(batchUserResp.items || []),
            ];
          });
        }
      } else if (typeof user === 'string') {
        state.schedules.value = [
          ...state.schedules.value.filter((availability) => !strEq(availability?.owner, user)),
          ...action.payload?.items,
        ];
      } else {
        state.schedules.value = action.payload?.items;
      }
      state.schedules.isLoading = false;
      state.schedules.hasLoaded = true;
    });
    builder.addCase(listSchedules.rejected, (state, action) => {
      state.schedules.isLoading = false;
      state.schedules.errorState = {
        isError: true,
        reason: 'storeErrors.listSchedules',
      };
    });

    builder.addCase(addSchedule.pending, (state, action) => {
      state.schedules.isLoading = true;
      state.schedules.errorState.isError = false;
    });
    builder.addCase(addSchedule.fulfilled, (state, action) => {
      state.schedules.value = [...state.schedules.value, action.payload];
      state.schedules.isLoading = false;
    });
    builder.addCase(addSchedule.rejected, (state, action) => {
      state.schedules.isLoading = false;
    });

    builder.addCase(editSchedule.pending, (state, action) => {
      state.schedules.errorState.isError = false;
    });
    builder.addCase(editSchedule.fulfilled, (state, action) => {
      const matchId = (element) => element.id === action.payload.id;
      const index = state.schedules.value.findIndex(matchId);
      state.schedules.value[index] = action.payload;
      // state.schedules.value = merge(state.schedules.value, action.payload, 'id');
    });
    builder.addCase(editSchedule.rejected, (state, action) => {
      console.error('Error with updating availability ', action.error);
    });

    builder.addCase(removeSchedule.fulfilled, (state, action) => {
      state.schedules.value = state.schedules.value.filter(
        (availability) => action.meta.arg.availabilityId !== availability.id);
      if (action?.meta?.arg?.data?.defaultAvailabilityId) {
        state.schedules.value.find(
          (schedule) => schedule.id === action.meta.arg.data.defaultAvailabilityId
        ).default = true;
      }
    });
    builder.addCase(removeSchedule.rejected, (state, action) => {
      console.error('Error with deleting availability ', action.error);
    });

    builder.addCase(setDefaultSchedule.fulfilled, (state, action) => {
      // state.schedules.value.find((schedule) => schedule.default).default = false;
      state.schedules.value.find((schedule) => schedule.id === action.meta.arg.data.availabilityId).default = false;
      state.schedules.value.find((schedule) => schedule.id === action.meta.arg.newDefaultId).default = true;
      // const oldDefaultIndex = state.schedules.value.findIndex(
      //   (schedule) => schedule.id === action.meta.arg.data.availabilityId
      // );
      // state.schedules.value[oldDefaultIndex].default = false;
      // const newDefaultIndex = state.schedules.value.findIndex(
      //   (schedule) => schedule.id === action.meta.arg.newDefaultId
      // );
      // state.schedules.value[newDefaultIndex].default = true;
    });

    builder.addCase(listAppointmentsForAvailability.pending, (state, action) => {
      state.appointments.isLoading = true;
      state.appointments.errorState.isError = false;
    });
    builder.addCase(listAppointmentsForAvailability.fulfilled, (state, action) => {
      state.appointments.value = action.payload?.items;
      state.appointments.isLoading = false;
      state.appointments.hasLoaded = true;
    });
    builder.addCase(listAppointmentsForAvailability.rejected, (state, action) => {
      state.appointments.isLoading = false;
      state.appointments.errorState = {
        isError: true,
        reason: 'storeErrors.listSchedules',
      };
    });

    builder.addCase(editActiveAppts.pending, (state, action) => {
      state.appointments.isLoading = true;
      state.appointments.errorState.isError = false;
    });
    builder.addCase(editActiveAppts.fulfilled, (state, action) => {
      state.appointments.value = state.appointments.value.map((appt) => {
        const newAvailabilityId = action.meta.arg.data.appointmentIds?.includes(appt.appointmentId) ?
          action.meta.arg.availabilityId :
          appt.availabilityId === action.meta.arg.availabilityId ?
            '' : appt.availabilityId;
        return {
          ...appt,
          availabilityId: newAvailabilityId,
        };
      });
      state.appointments.isLoading = false;
    });
    builder.addCase(editActiveAppts.rejected, (state, action) => {
      console.error('Error with updating active appoiontments for availability', action.error);
      state.appointments.isLoading = false;
    });

    builder.addCase(listPhoneNumbers.pending, (state, action) => {
      state.isLoading = true;
      state.errorState.isError = false;
    });
    builder.addCase(listPhoneNumbers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.phoneNumbers = action.payload.value;
    });
    builder.addCase(listPhoneNumbers.rejected, (state, action) => {
      state.isLoading = false;
      state.errorState.isError = true;
      state.errorState.reason = 'storeErrors.listPhonesForCalendar';
    });

    builder.addCase(addPhoneNumber.fulfilled, (state, action) => {
      state.phoneNumbers = action.payload.value;
    });

    builder.addCase(editPhoneNumber.fulfilled, (state, action) => {
      state.phoneNumbers = action.payload.value;
    });

    builder.addCase(switchPrimaryPhone.fulfilled, (state, action) => {
      state.phoneNumbers = action.payload.value;
    });

    builder.addCase(deletePhone.fulfilled, (state, action) => {
      state.phoneNumbers = action.payload.value;
    });

    builder.addCase(getUserSlug.pending, (state, action) => {
      if (!action.meta.arg?.userId) {
        state.slug.isLoading = true;
        state.slug.errorState.isError = false;
      } else {
        const userId = action.meta.arg?.userId.toLowerCase();
        state.slugs[userId] = {
          ...state.slugs[userId],
          isLoading: true,
          errorState: {
            isError: false,
          },
        };
      }
    });
    builder.addCase(getUserSlug.fulfilled, (state, action) => {
      if (!action.meta.arg?.userId) {
        state.slug.isLoading = false;
        state.slug.hasLoaded = true;
        state.slug.value = action.payload.value;
        state.slug.slugFormatEnabled = !!action.payload.slugFormatEnabled;
      } else {
        const userId = action.meta.arg?.userId.toLowerCase();
        state.slugs[userId] = {
          loading: false,
          hasLoaded: true,
          value: action.payload.value,
          slugFormatEnabled: !!action.payload.slugFormatEnabled,
        };
      }
    });
    builder.addCase(getUserSlug.rejected, (state, action) => {
      if (!action.meta.arg?.userId) {
        state.slug.isLoading = false;
        state.slug.errorState = {
          isError: true,
          reason: 'storeErrors.fetchUserSlugSetting',
        };
      } else {
        const userId = action.meta.arg?.userId.toLowerCase();
        state.slugs[userId].isLoading = false;
        state.slugs[userId].errorState = {
          isError: true,
          reason: 'storeErrors.fetchUserSlugSetting',
        };
      }
    });

    builder.addCase(patchUserSlug.fulfilled, (state, action) => {
      state.slug.value = action.payload.value;
    });

    builder.addCase(getMeetingDefaults.pending, (state, action) => {
      state.meetingSettings.isLoading = true;
      state.meetingSettings.errorState.isError = false;
    });
    builder.addCase(getMeetingDefaults.fulfilled, (state, action) => {
      state.meetingSettings.isLoading = false;
      state.meetingSettings.hasLoaded = true;
      const userMeetingSettings = Object.values(action.payload.value.data)[0];
      state.meetingSettings.value = userMeetingSettings;
    });
    builder.addCase(getMeetingDefaults.rejected, (state, action) => {
      state.meetingSettings.isLoading = false;
      state.meetingSettings.errorState = {
        isError: true,
        reason: 'storeErrors.fetchUserSlugSetting',
      };
    });

    builder.addCase(uploadBinaryImage.pending, (state, action) => {
      state.uploadBinaryImageLoading = true;
    });
    builder.addCase(uploadBinaryImage.fulfilled, (state, action) => {
      state.uploadBinaryImageLoading = false;
    });
    builder.addCase(uploadBinaryImage.rejected, (state, action) => {
      state.uploadBinaryImageLoading = false;
    });
  },
});

export const {
  setHasFixedBottomDialog,
  setTimeFormat,
  setWalkthrough,
  setWalkthroughCompleted,
} = hostSettingsStore.actions;

export default hostSettingsStore.reducer;
