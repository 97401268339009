import React, {useCallback, useMemo} from 'react';

import {MenuItem, Typography, styled} from '@mui/material';
import {IconChevronRightSmall} from '@zoom/sun-ui-icon';

import {ZmMenu} from 'Components/ZmDialogs';
import zoomPalette from 'zoomPalette';

// const placementMap = {
//   // 'auto-end': [],
//   // 'auto-start': [],
//   // 'auto': [],
//   'bottom-end': [],
//   'bottom-start': [],
//   'bottom': [],
//   'left-end': [],
//   'left-start': [],
//   'left': [],
//   'right-end': [],
//   'right-start': [],
//   'right': [],
//   'top-end': [],
//   'top-start': [],
//   'top': [],
// };


export const CustomMenu = styled(ZmMenu)(({theme}) => ({
  '.MuiMenu-paper': {
    padding: '4px',
    minWidth: '254px',
  },
  '& .MuiMenuItem-root': {
    'padding': '4px',
    'borderRadius': '4px',
    '.MuiTypography-root': {
      'fontSize': '14px',
    },
    '&.Mui-focusVisible': {
      backgroundColor: zoomPalette.white,
    },
  },

}));

const ZmDropdown = (props) => {
  const {children, items, placement} = props;
  const anchor = React.useRef(null);

  const anchorOrigin = placement?.split('-') || [];

  const [open, setOpen] = React.useState(false);

  const handleOpen = useCallback((e) => {
    anchor.current = anchor.current || e.currentTarget;
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const elementCloned = useMemo(() => React.cloneElement(children, {
    // ref: anchor,
    onClick: handleOpen,
  }), [children, handleOpen]);

  console.log(anchorOrigin);

  return (
    <>
      {elementCloned}
      <CustomMenu
        anchorEl={anchor.current}
        id="basic-menu"
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: anchorOrigin[0] || 'bottom',
          horizontal: anchorOrigin[1] || 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {items.map((item, index) => {
          const child = (
            <MenuItem onClick={item.onClick}>
              {item.icon}
              <Typography sx={{ml: item.icon ? 1: 0, flexGrow: 1}}>{item.label}</Typography>
              {!!item.children && <IconChevronRightSmall />}
            </MenuItem>
          );
          return !item.children ? child :
            (<ZmDropdown items={item.children} key={item.key || index} placement={item.placement}>
              {child}
            </ZmDropdown>);
        })}
      </CustomMenu>
    </>
  );
};

export default ZmDropdown;
