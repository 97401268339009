import React from 'react';

import {
  Stack,
  Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {
  BadgeContainer, BadgeContent, CustomUserLogo, LogoContent, ZmBadgeLogo, ZmLogo, ZmTopBar,
} from './Style';

import zoomLogo from 'assets/svg/zoomLogo.svg';
import ZmTranslationSelector from 'Components/ZmTranslationSelector';
import {LOGO_EMPTY} from 'Store/LogoStore';
import {isAnonymousTeamAppt} from 'Utils/apptAvailabilityUtils';
import zoomPalette from 'zoomPalette';

/**
 * Zoom Product Badge component
 * @param {Object} props
 * @return {ReactElement}
 */
export function ZmProductBadge() {
  return (
    <BadgeContainer>
      <BadgeContent>
        <Typography sx={{
          fontSize: '.875rem',
          lineHeight: '1.125rem',
          fontWeight: '600',
          color: zoomPalette.gray[60],
        }}>
        Powered by
        </Typography>
        <ZmBadgeLogo src={zoomLogo} alt="Zoom Logo" />
      </BadgeContent>
    </BadgeContainer>
  );
}

/**
 * Zoom Web Header Toolbar component
 * @param {Object} props
 * @return {ReactElement}
 */
export default function ZmWebHeader() {
  const {t} = useTranslation();
  const logoEnforceUse = useSelector((state) => state.logoState.logoEnforceUse);
  const appt = useSelector((s) => s.bookAppointments.appt);
  const showPoweredByZoom = useSelector((s) => s.bookAppointments.showPoweredByZoom);
  const logo = useSelector((state) => state.logoState.logo);
  const isLoaded = useSelector((state) => state.logoState.isLoaded);

  let useDefaultZoomLogo = logo === LOGO_EMPTY;
  if (isAnonymousTeamAppt(appt) && !logoEnforceUse) {
    // use default zoom logo for anonymous team appt if no account level logo
    // is enforced
    useDefaultZoomLogo = true;
  }
  return (
    <ZmTopBar>
      {isLoaded && (
        <>
          <LogoContent isZoom={useDefaultZoomLogo}>
            {(useDefaultZoomLogo) ?
              <ZmLogo
                src={zoomLogo}
                alt={t('common.zoomLogo')}
              /> :
              <Stack sx={{
                flexDirection: 'row',
                alignItems: 'center',
                height: '100%',
                gap: '8px',
                overflow: 'hidden',
              }}>
                <CustomUserLogo src={logo} alt={t('branding.logoPrompt')} />
                {showPoweredByZoom && <ZmProductBadge />}
              </Stack>}
          </LogoContent>
          <ZmTranslationSelector />
        </>
      )}
    </ZmTopBar>
  );
}
