import {createSlice, nanoid} from '@reduxjs/toolkit';

import {putBookingPageColor} from './BookingPageColorStore';
import {listBusyEventsForCalendar} from './HostBusyEventsStore';
import {
  addPhoneNumber,
  addSchedule,
  deletePhone,
  editPhoneNumber,
  patchNotificationEmail,
  patchUserSlug,
  uploadBinaryImage,
} from './HostSettingsStore';
import {LOGO_EMPTY, putPersonalLogo} from './LogoStore';
import {
  assignManagedEventThunk,
  cloneManagedEventThunk,
  removeManagedEvent,
  unassignManagedEventThunk,
} from './ManagedEventsStore';
import {deleteRoutingFormCalendar, duplicateFormCalendar, listFormsForCalendar} from './RoutingStore';

import {fetchReportsEvents} from 'Store/AnalyticsStore';
import {
  addGroup,
  assignAsMember,
  removeGroup,
  removeAsMember,
  assignAsAdmin,
  removeAsAdmin,
  editGroup,
} from 'Store/GroupStore';
import {
  deleteAppointmentForCalendar,
  listAppointmentsForCalendar,
  bookPollForCalendar,
} from 'Store/HostAppointmentsStore';
import {
  deleteBookingForCalendar,
  deleteEventBookerForCalendar,
  listBookingsForCalendar,
  removePendingEvent,
} from 'Store/HostBookingsStore';
import {
  deleteWorkflowForCalendar,
  editWorkflowForCalendar,
  listWorkflowsForCalendar,
  updateApptWorkflows,
} from 'Store/HostWorkflowsStore';
import {
  listAccounts,
  switchAccount,
  connectAccount,
  disconnectAccount,
  updateAvailabilityPreferences,
  notifyHostConnectCalendar,
  connectMeetIntegration,
  disconnectMeetingIntegration,
} from 'Store/IntegrationStore';
import {
  addTeam,
  assignTeamMember,
  editTeam,
  listTeamMembers,
  removeTeamMember,
} from 'Store/TeamStore';

const slice = createSlice({
  name: 'SnackbarStore',
  initialState: [],
  reducers: {
    removeSnackbar: (state, action) => {
      const index = state.findIndex((item) => item.id === action.payload);
      state.splice(index, 1);
    },
    addSnackbar: (state, action) => {
      state.push({
        message: action.payload.message,
        severity: action.payload.severity,
        id: nanoid(),
        noIcon: action.payload.noIcon,
        verticalPosition: action.payload.verticalPosition ?? 'top',
        theme: action.payload.theme ?? 'mui',
      });
    },
  },
  extraReducers: (builder) => {
    const build = (route, severity, message, options = {}) => {
      const {noIcon=false, theme = 'mui', verticalPosition = 'top'} = options;
      builder.addCase(route, (state, action) => {
        let finalMessage = message;
        if (typeof(message) === 'function') {
          finalMessage = message(action);
        }

        state.push({
          message: finalMessage,
          severity,
          id: nanoid(),
          noIcon,
          verticalPosition,
          theme,
        });
      });
    };

    build(listAppointmentsForCalendar.rejected,
      'warning', 'Failed to fetch your appointment schedules');
    build(deleteAppointmentForCalendar.fulfilled,
      'success', 'Your appointment schedule was successfully deleted');
    build(deleteAppointmentForCalendar.rejected,
      'warning', 'Failed to delete appointment');
    build(listBookingsForCalendar.rejected,
      'warning', 'Failed to fetch your booked events');
    build(deleteBookingForCalendar.fulfilled,
      'success', 'Your booked event was successfully deleted');
    build(deleteBookingForCalendar.rejected,
      'warning', 'Failed to delete booked event');
    build(deleteEventBookerForCalendar.fulfilled,
      'success', 'Booker was successfully removed');
    build(deleteEventBookerForCalendar.rejected,
      'warning', 'Failed to remove booker');

    build(listWorkflowsForCalendar.rejected,
      'warning', 'Failed to fetch your workflows');
    build(editWorkflowForCalendar.fulfilled,
      'success', 'Your workflow was successfully edited');
    build(editWorkflowForCalendar.rejected,
      'warning', 'Failed to edit workflow');
    build(deleteWorkflowForCalendar.fulfilled,
      'success', 'Your workflow was successfully deleted');
    build(deleteWorkflowForCalendar.rejected,
      'warning', 'Failed to delete workflow');
    // build(updateApptWorkflows.fulfilled,
    //   'success', 'Your booking schedule was successfully updated');
    build(updateApptWorkflows.rejected,
      'warning', 'Failed to update your booking schedules');
    build(connectMeetIntegration.fulfilled, 'success', 'integration.connectSuccess');
    build(connectMeetIntegration.rejected, 'warning', 'integration.connectFailed');
    build(disconnectMeetingIntegration.fulfilled,
      'success', 'integration.disconnectSuccess');
    build(disconnectMeetingIntegration.rejected,
      'warning', 'integration.disconnectFailed');
    build(listAccounts.rejected,
      'warning', 'integration.listAccountFailed');
    build(switchAccount.fulfilled,
      'success', 'integration.switchSuccess');
    build(switchAccount.rejected,
      'warning', 'integration.switchFailed');
    build(
      connectAccount.fulfilled,
      'success',
      (action) => {
        return action.payload.isReAuthorized?
        'integration.reAuthorizeSuccess': 'integration.connectSuccess';
      }
    );
    build(connectAccount.rejected, 'warning', (action) => action.payload);
    build(
      disconnectAccount.fulfilled,
      'success',
      (action) => {
        return action.payload.switchedEmail?
        {
          msg: 'integration.disconnectActiveSuccess',
          params: {email: action.payload.switchedEmail},
        }: 'integration.disconnectSuccess';
      }
    );
    build(disconnectAccount.rejected,
      'warning', 'integration.disconnectFailed');

    build(
      putPersonalLogo.rejected,
      'warning',
      (action) => {
        return action.meta.arg.data === LOGO_EMPTY ?
        'branding.logoDeleteFailed' : 'branding.logoUpdateFailed';
      }
    );
    build(
      putBookingPageColor.fulfilled,
      'success',
      'branding.bookingPageColorsHaveUpdated',
    );
    build(
      addPhoneNumber.fulfilled,
      'success',
      'integration.addPhoneNumberSuccess',
    );
    build(
      editPhoneNumber.fulfilled,
      'success',
      'integration.editPhoneNumberSuccess',
    );
    build(
      deletePhone.fulfilled,
      'success',
      'integration.deletePhoneNumberSuccess',
    );
    build(
      deletePhone.rejected,
      'warning',
      'integration.deletePhoneNumberFailed',
    );
    build(addGroup.fulfilled, 'success', 'group.createSuccess');
    build(addGroup.rejected, 'warning', 'group.createFailed');
    build(editGroup.fulfilled, 'success', 'common.generalSuccessTip');
    build(editGroup.rejected, 'warning', 'common.generalFailureTip');
    build(removeGroup.fulfilled, 'success', 'group.deleteSuccess');
    build(removeGroup.rejected, 'warning', 'group.deleteFailed');
    build(assignAsMember.fulfilled, 'success', 'common.generalSuccessTip');
    build(assignAsMember.rejected, 'error', (action) => {
      return action.error.message === '409'? 'group.memberAlreadyInGroup': 'common.generalFailureTip';
    });
    build(removeAsMember.fulfilled, 'success', 'common.generalSuccessTip');
    build(removeAsMember.rejected, 'error', 'common.generalFailureTip');
    build(assignAsAdmin.fulfilled, 'success', 'common.generalSuccessTip');
    build(assignAsAdmin.rejected, 'error', 'common.generalFailureTip');
    build(removeAsAdmin.fulfilled, 'success', 'common.generalSuccessTip');
    build(removeAsAdmin.rejected, 'error', 'common.generalFailureTip');

    build(notifyHostConnectCalendar.fulfilled, 'success', 'apptForm.notifyHostSuccess');
    build(notifyHostConnectCalendar.rejected, 'error', 'common.generalFailureTip');

    build(uploadBinaryImage.rejected, 'error', (action) => action.payload);

    build(
      patchUserSlug.fulfilled,
      'success',
      'integration.editUserSlugSuccess',
    );
    build(
      patchUserSlug.rejected,
      'warning',
      'integration.editUserSlugFailed',
    );

    build(
      updateAvailabilityPreferences.fulfilled,
      'success',
      'integration.editAvailabilitySuccess',
    );
    build(
      updateAvailabilityPreferences.rejected,
      'warning',
      'integration.editAvailabilityFailed',
    );
    build(removePendingEvent.fulfilled, 'success', 'integration.deletePendingSuccess');
    build(removePendingEvent.rejected, 'warning', 'integration.deletePendingFailed');
    build(fetchReportsEvents.rejected, 'warning', 'analytics.getEventDataFailed');

    build(
      listBusyEventsForCalendar.rejected,
      'warning',
      'apptForm.errorFetchingBusy',
    );
    build(removeManagedEvent.fulfilled, 'success', 'managedEvents.removeSuccessTip');
    build(removeManagedEvent.rejected, 'warning', 'managedEvents.removeFailed');
    build(bookPollForCalendar.fulfilled, 'success', 'integration.bookPollSuccess');
    build(bookPollForCalendar.rejected, 'warning', 'integration.bookPollFailed');
    build(cloneManagedEventThunk.rejected, 'warning', 'managedEvents.cloneFailed');
    build(assignManagedEventThunk.fulfilled, 'success', 'managedEvents.asyncOperationTip');
    build(unassignManagedEventThunk.fulfilled, 'success', 'managedEvents.asyncOperationTip');
    build(assignManagedEventThunk.rejected, 'warning', 'managedEvents.assignFailed');
    build(unassignManagedEventThunk.rejected, 'warning', 'managedEvents.unassignFailed');
    build(addSchedule.fulfilled, 'success', 'common.generalSuccessTip');
    build(addSchedule.rejected, 'warning', 'common.generalFailureTip');
    build(listTeamMembers.rejected, 'warning', 'team.listMemberFail');
    build(addTeam.fulfilled, 'success', 'common.generalSuccessTip');
    build(addTeam.rejected, 'warning', 'common.generalFailureTip');
    build(editTeam.fulfilled, 'success', 'common.generalSuccessTip');
    build(editTeam.rejected, 'warning', 'common.generalFailureTip');
    build(assignTeamMember.fulfilled, 'success', 'common.generalSuccessTip');
    build(assignTeamMember.rejected, 'warning', 'common.generalFailureTip');
    build(removeTeamMember.fulfilled, 'success', 'common.generalSuccessTip');
    build(removeTeamMember.rejected, 'warning', 'common.generalFailureTip');
    build(listFormsForCalendar.rejected, 'warning', 'routing.getRoutingFormsCalendarFailed');
    build(deleteRoutingFormCalendar.rejected, 'warning', 'routing.deleteRoutingFormCalendarFailed');
    build(duplicateFormCalendar.rejected, 'warning', 'routing.duplicateFormCalendarFailed');
    build(patchNotificationEmail.rejected, 'warning', 'common.generalFailureTip');
  },
});
export const snackbarActions = slice.actions;
export default slice.reducer;
