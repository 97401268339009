import {store} from 'Store/Store';

import {ZOOM_WEB_DOMAIN} from './consts';

import {snackbarActions} from 'Store/SnackbarStore';

export const openUrlInBrowser = async (url) => {
  try {
    if (window.zoomSdk) {
      await window.zoomSdk.postJsMessage({
        functionName: 'calendarui_opendefaultbrowser',
        params: {
          url,
        },
      });
      // prompt user to notice the pop-up page in browser
      store.dispatch(snackbarActions.addSnackbar({
        message: 'common.continueInBrowser',
        severity: 'success',
      }));
    } else {
      window.open(url, '_blank');
    }
  } catch (e) {
    console.warn('failed to open url in browser', url);
  }
};

export const WEB_SCHEDULER_URL = `${ZOOM_WEB_DOMAIN}/appointment`;

export const joinZoomMeeting = async ({
  meetingNo,
  password,
  url,
}) => {
  try {
    await window.zoomSdk.postJsMessage({
      functionName: 'calendarui_joinzoommeeting',
      params: {
        meeting: {
          meetingNo: parseInt(meetingNo),
          password,
        },
      },
    });
  } catch (e) {
    console.warn('failed to join zoom meeting', meetingNo, password);
  }
};

export const saveBase64ImageToDisk = async (base64Image) => {
  try {
    await window.zoomSdk.postJsMessage({
      functionName: 'calendarui_savebase64image',
      params: {
        fileName: 'QRCode.png',
        base64Image,
      },
      timeout: 5000,
    });
  } catch (e) {
    // older client may not support this js call
    if (e.errorCode === -1) {
      store.dispatch(snackbarActions.addSnackbar({
        message: 'common.clientNotSupport',
        severity: 'error',
      }));
    }
    console.warn('failed to save base64 image', e);
  }
};

// eslint-disable-next-line max-len
export const isMobile = navigator.userAgent.match(
  // eslint-disable-next-line max-len
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);
