import React, {forwardRef} from 'react';

import {Button, ButtonProps} from '@mui/material';

type ZmButtonProps = ButtonProps & {destructive?: boolean};
export const ForwardRefButton = forwardRef<HTMLButtonElement, ZmButtonProps>(
  ({children, ...props}, ref) => {
    return (<Button
      {...props}
      ref={ref}
    >
      {children}
    </Button>);
  },
);
