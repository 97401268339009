import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {getDomainLogoExtFromServer} from 'Api/Calendar';
import {getPersonalLogo, patchPersonalLogo} from 'Api/ZCalendar';
import {HttpError} from 'Error/HttpError';

export const LOGO_EMPTY = ''; // If a logo was set and deleted

const logoSessionVal = sessionStorage.getItem('logoState');
const logoValExists = (!!logoSessionVal || logoSessionVal === LOGO_EMPTY);

const initialState = {
  logo: (logoValExists) ? logoSessionVal : null,
  isLoading: false,
  isLoaded: logoValExists, // determine if the data has been loaded
  logoEnforceUse: false,
};

export const getDomainLogo = createAsyncThunk(
  'logo/getDomainLogo',
  async (thunkAPI) => {
    try {
      const response = await getPersonalLogo();
      sessionStorage.setItem('logoState', makeDataUrl(response?.value?.data));
      return response;
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.errorCode === 404) {
          sessionStorage.setItem('logoState', LOGO_EMPTY);
          return ({
            id: 'logo',
            value: {
              data: '',
            },
          });
        }
      }
      throw e;
    }
  }
);

export const getDomainLogoExt = createAsyncThunk(
  'logo/getDomainLogoExt',
  async (calId, thunkAPI) => {
    try {
      const response = await getDomainLogoExtFromServer(calId);
      sessionStorage.setItem('logoState', makeDataUrl(response?.value?.data));
      return response;
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.errorCode === 404) {
          sessionStorage.setItem('logoState', LOGO_EMPTY);
          return ({
            id: 'logo',
            value: {
              data: '',
            },
          });
        }
      }
      throw e;
    }
  }
);

export const putPersonalLogo = createAsyncThunk(
  'logo/patchPersonalLogo',
  async ({data}, thunkAPI) => {
    const response = await patchPersonalLogo({
      id: 'logo',
      value: {
        data: data,
      },
    });
    sessionStorage.setItem('logoState', makeDataUrl(data));
    return response;
  }
);

/**
 * Server will store logo data as a base64 string, not a data url. Converts given b64 string to data url for usage
 * @param {?string} data
 * @return {string} logo b64 data url or empty string
 */
const makeDataUrl = (data) => {
  if (data) {
    if (data.startsWith('data:image')) {
      return data;
    }
    return 'data:image/webp;base64,' + data;
  } else {
    return LOGO_EMPTY;
  }
};

const onDomainLogoFulfilled = (state, action) => {
  const logoInfo = action.payload?.value;
  if (logoInfo) {
    state.logoEnforceUse = logoInfo.logoEnforceUse ?? false;
    // SVG attacks can cause browser crash, we do not support SVG logo
    const isSvg = logoInfo.data?.startsWith('data:image/svg+xml');
    const useUserLogo = !state.logoEnforceUse && logoInfo.from === 'user';
    const useAccountLogo = state.logoEnforceUse && logoInfo.from === 'admin';
    if ((useUserLogo || useAccountLogo) && !isSvg) {
      state.logo = makeDataUrl(logoInfo.data);
    } else {
      state.logo = '';
    }
  } else {
    state.logo = '';
  }
  state.isLoading = false;
  state.isLoaded = true;
};

const onDomainLogoExtRejected = (state) => {
  state.logo = LOGO_EMPTY;
  state.isLoaded = true;
};

export const logoState = createSlice({
  name: 'Logo',
  initialState,
  reducers: {
    setDomainLogoExt(state, action) {
      if (action.payload.error) {
        onDomainLogoExtRejected(state);
      } else {
        onDomainLogoFulfilled(state, action);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDomainLogo.fulfilled, onDomainLogoFulfilled);
    builder.addCase(getDomainLogo.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getDomainLogo.rejected, (state, action) => {
      state.logo = LOGO_EMPTY;
      state.isLoading = false;
      state.isLoaded = true;
    });

    builder.addCase(getDomainLogoExt.fulfilled, onDomainLogoFulfilled);
    builder.addCase(getDomainLogoExt.rejected, onDomainLogoExtRejected);

    builder.addCase(putPersonalLogo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.logo = makeDataUrl(action.payload?.value?.data);
    });
    builder.addCase(putPersonalLogo.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(putPersonalLogo.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  setDomainLogoExt,
} = logoState.actions;

export default logoState.reducer;
