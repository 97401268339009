import {styled} from '@mui/system';

import {ForwardRefButton} from './ForwardRefButton';

import zoomPalette from 'zoomPalette';


const zmButtonBaseStyle = ({theme}) => `
&.MuiButtonBase-root {
  box-shadow: unset;
  text-transform: unset;
  &:hover: {
    box-shadow: unset,
  }
  &:active: {
    box-shadow: unset;
  }
  &.Mui-focusVisible {
    box-shadow: 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px ${theme.palette.primary.swatch['60']};
  }
  &.Mui-disabled {
    color: ${theme.palette.common.swatch['60']};
    background-color: ${theme.palette.background.active};
  }
  & .MuiButton-endIcon.MuiButton-iconSizeXsmall {
    margin-left: 4px;
    margin-right: -4px;
  }
  & .MuiButton-startIcon.MuiButton-iconSizeXsmall {
    margin-right: 4px;
    margin-left: 0px;
  }
}
`;

const primaryStyle = ({theme}) => `
  color: ${theme.palette.common.white};
  background: ${theme.palette.button.default};
  &:hover {
    background: ${theme.palette.button.hover};
  }
  &:active {
    background: ${theme.palette.button.active};
  }
  &.Mui-disabled {
    color: ${theme.palette.common.swatch['60']};
    background: ${theme.palette.common.swatch['20']};
  }
`;

const warningStyle = ({theme}) => `
  color: ${theme.palette.common.white};
  background: ${theme.palette.error.main};
  &:hover {
    background: ${theme.palette.error.dark};
  }
  &:active {
    background: ${theme.palette.error['80']};
  }
`;

const primaryNagetiveStyle = ({theme}) => `
color: ${theme.palette.common.white};
background: ${theme.palette.error.swatch['60']};
&:hover {
  background: ${theme.palette.error.swatch['70']};
}
&:active {
  background: ${theme.palette.error.swatch['80']};
}
&.Mui-disabled {
  color: ${theme.palette.common.swatch['60']};
  background: ${theme.palette.common.swatch['20']};
}
`;

const secondaryStyle = ({theme}) => `
  color: ${theme.palette.common.swatch['90']};
  background: ${theme.palette.common.swatch['20']};
  &:hover {
    background: ${theme.palette.common.swatch['30']};
  }
  &:active {
    background: ${theme.palette.common.swatch['40']};
  }
  &.Mui-disabled {
    color: ${theme.palette.common.swatch['60']};
    background: ${theme.palette.common.swatch['20']};
  }
`;

const tertiaryStyle = ({theme}) => `
  color: ${theme.palette.common.swatch['90']};
  background: ${theme.palette.common.white};
  border: 1px solid ${theme.palette.common.swatch['30']};
  &:hover {
    background: ${theme.palette.common.swatch['10']};
  }
  &:active {
    background: ${theme.palette.common.swatch['20']};
  }
  &.Mui-disabled {
    color: ${theme.palette.common.swatch['60']};
    background: ${theme.palette.common.white} !important;
  }
`;

const quaternaryStyle = ({destructive, theme}) => `
  color: ${destructive ? theme.palette.error.swatch['60'] : theme.palette.primary.swatch['70']};
  background: transparent;
  &:hover {
    background: ${destructive ? theme.palette.error.swatch['10'] : theme.palette.primary.swatch['10']};
  }
  &:active {
    background: ${destructive ? theme.palette.error.swatch['20'] : theme.palette.primary.swatch['20']};
  }
  &.Mui-disabled {
    color: ${theme.palette.common.swatch['60']};
    background: ${theme.palette.common.white} !important;
  }
`;

const quinaryStyle = ({theme}) => `
  color: ${theme.palette.text.primary};
  &:hover {
    background: ${theme.palette.background.hover};
  }
  &:active {
    background: ${theme.palette.background.active};
  }
  &.Mui-disabled {
    color: ${theme.palette.common.swatch['60']};
    background: ${theme.palette.common.white};
  }
`;

export const ZmWarningBtn = styled(ForwardRefButton, {shouldForwardProp: () => true})(
  (props) =>
    `
    ${zmButtonBaseStyle(props)}
    ${warningStyle(props)}
    `);

export const ZmPrimaryBtn = styled(ForwardRefButton, {shouldForwardProp: () => true})(
  (props) =>
    `
    ${zmButtonBaseStyle(props)}
    ${primaryStyle(props)}
    `);

export const ZmPrimaryBtnNeg = styled(ForwardRefButton, {shouldForwardProp: () => true})(
  (props) =>
    `
    ${zmButtonBaseStyle(props)}
    ${primaryNagetiveStyle(props)}
    `);

export const ZmSecondaryBtn = styled(ForwardRefButton, {shouldForwardProp: () => true})(
  (props) =>
    `
    ${zmButtonBaseStyle(props)}
    ${secondaryStyle(props)}
    `);

export const ZmTertiaryBtn = styled(ForwardRefButton, {shouldForwardProp: () => true})(
  (props) =>
    `
    ${zmButtonBaseStyle(props)}
    ${tertiaryStyle(props)}
    `);

export const ZmQuaternaryBtn = styled(ForwardRefButton, {shouldForwardProp: () => true})(
  (props) =>
    `
    ${zmButtonBaseStyle(props)}
    ${quaternaryStyle(props)}
    `);

export const ZmQuinaryBtn = styled(ForwardRefButton, {shouldForwardProp: () => true})(
  (props) =>
    `
    ${zmButtonBaseStyle(props)}
    ${quinaryStyle(props)}
    `);

export const ZmTextBtn = styled(ForwardRefButton, {shouldForwardProp: () => true})(({theme}) => ({
  'color': theme.palette.text.primary,
  'background': 'transparent',
  'height': '32px',
  'fontSize': '14px',
  'justifyContent': 'center',
  'fontWeight': 'normal',
  'borderRadius': '6px',
  'padding': '6px 8px',
  '&:hover': {
    background: zoomPalette.lightBlue,
  },
  '&.isActive': {
    color: theme.palette.primary.light,
    background: theme.palette.primary.altLight,
  },
}));
