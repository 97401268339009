import {useCallback, useEffect, useState} from 'react';

import {MenuItem, styled} from '@mui/material';
import {IconCheckFill, IconTriangleDown, IconTriangleUp} from '@zoom/sun-ui-icon';
import {useTranslation} from 'react-i18next';

import {ZmQuaternaryBtn} from 'Components/ZmButtons';
import {CustomMenu} from 'Components/ZmMenu';
import {LNG_LIST, LNG_LIST_MAP} from 'Utils/consts';
import zoomPalette from 'zoomPalette';

const MenuItemStyled = styled(MenuItem)(() => ({
  padding: '8px 9px !important',
  height: '32px',
  minHeight: '32px',
  justifyContent: 'space-between',
  [`&:not(:hover) .sun-ui-icon-common-style`]: {
    color: zoomPalette.blue[60],
  },
}));

const CustomMenuSx = {
  '.MuiMenu-paper': {
    minWidth: '150px !important',
  },
};

const LanguageList = () => {
  const {i18n} = useTranslation();

  const handleOnClick = (lng) => {
    i18n.changeLanguage(lng);
    // Temporary key to set the language of the booking page independently from the web
    // Future updates will support synchronizing and modifying the web environment language.
    localStorage.setItem('bookingPage_i18nextLng', lng);
  };

  return (
    <>
      {LNG_LIST.map((lng) => (
        <MenuItemStyled onClick={() => handleOnClick(lng)} data-testid={`language-item-${lng}`}>
          {LNG_LIST_MAP[lng]}
          {i18n.language === lng && <IconCheckFill />}
        </MenuItemStyled>
      ))}
    </>
  );
};

const ZmTranslationSelector = () => {
  const {i18n} = useTranslation();

  const [open, setOpen] = useState(false);

  const handleOnToggle = useCallback((v) => setOpen(v), []);
  useEffect(() => {
    const webPortalLanguage = localStorage.getItem('i18nextLng') || 'en-US';
    const savedLanguage = localStorage.getItem('bookingPage_i18nextLng');
    i18n.changeLanguage(savedLanguage || webPortalLanguage);
  }, [i18n]);

  return (
    <CustomMenu content={<LanguageList />} onToggle={handleOnToggle} sx={CustomMenuSx}>
      <ZmQuaternaryBtn size="xsmall" data-testid={`language-switch-${i18n.language || 'en-US'}`}>
        {LNG_LIST_MAP[i18n.language || 'en-US']}
        {open ? <IconTriangleUp /> : <IconTriangleDown />}
      </ZmQuaternaryBtn>
    </CustomMenu>
  );
};

export default ZmTranslationSelector;
